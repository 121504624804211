.file-upload-label {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.file-upload-banner {
  display: flex;
  gap: 10px;
  background-color: #f1f3f5;
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: 15px;
}

.file-upload-text {
  font-size: 14px;
  line-height: 17px;
}

.file-upload-wrapper {
  margin-top: 20px;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: #e2e8f0;
  display: flex;
  padding: 20px;
  justify-content: center;
  cursor: pointer;
  &:active,
  &:focus,
  &:hover {
    border-width: 2px;
    border-color: silver;
  }
  &.file-upload-wrapper-no-title {
    margin-top: 0px;
  }
}

.file-upload-instructions-wrapper {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.file-upload-instructions {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
}

.file-upload-instructions-title {
  font-weight: 700;
}

.file-upload-instructions-max {
  color: #5f666d;
}

.file-upload-info {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  background-color: #ffe7b3;
  border-radius: 8px;
  margin-top: 20px;
}

.file-upload-info-error {
  background-color: #ffabab;
}

.file-upload-preview-wrapper {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.file-upload-preview-image-wrapper {
  margin-top: 17px;
  position: relative;
  align-self: end;
}

.file-upload-preview-image {
  width: 168px;
  height: 108px;
  margin-top: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #d9d9d9;
}

.file-upload-preview-image-new {
  border-style: solid;
}

.file-upload-preview-delete {
  position: absolute;
  right: 7px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.file-upload-preview-delete:hover {
  color: red;
}
