.dropdown {
  position: relative;
  width: 100%;
  text-align: left;
}

.dropdown-top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #334150;
}

.dropdown-content {
  margin-top: 3px;
  color: #8f9bb3;
}

.dropdown-content.selected-value {
  color: #333;
}

.placeholder {
  color: #8f9bb3;
}

.dropdown-button {
  padding: 13px 20px 13px 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #c5cee0;
  border-radius: 4px;
  font-size: 1.25rem;
  outline: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  background-color: #fff;
  gap: 20px;
}

.dropdown-button:hover {
  opacity: 0.9;
}

.dropdown-nav {
  display: none;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  padding: 5px 0;
  border-radius: 4px;
  background-color: #fff;
  z-index: 200;
  max-height: 170px;
  overflow-y: scroll;
}

.dropdown-nav-no-padding {
  top: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 70px;
  left: -10px;
}

.dropdown-nav ul {
  list-style-type: none;
  padding: 0px 20px;
}

.dropdown-nav-no-padding ul {
  padding: 0px;
}

.dropdown-nav ul li {
  text-align: left;
  cursor: pointer;
  padding: 5px 10px;
}

.dropdown-nav-no-padding ul li {
  padding: 0px;
}

.dropdown-nav ul li button {
  width: 100%;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.dropdown-nav ul li:hover {
  background-color: silver;
}

.dropdown-nav ul li:hover a {
  color: #fff;
}

.dropdown-nav.dropdown-is-open {
  display: block;
  border-style: solid;
  border-color: #c5cee0;
  border-width: 1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.dropdown-button.dropdown-is-open::after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  vertical-align: middle;
}

.tooltip {
  position: relative;
  display: inline-block;
  height: 22px;
  bottom: 5px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;

  &.tooltip-left {
    top: -5px;
    right: 105%;
  }

  &.tooltip-top {
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
  }

  &.tooltip-right {
    top: -5px;
    left: 105%;
  }

  &.tooltip-bottom {
    top: 100%;
    left: 50%;
    margin-left: -60px;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Event list scrollbar */
.dropdown-nav::-webkit-scrollbar {
  display: block;
  width: 8px;
}

.dropdown-nav::-webkit-scrollbar-track {
  background: none;
}

.dropdown-nav::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.dropdown-nav::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-corner {
  background: none;
}
