/* Hide the logo */
// .mapboxgl-ctrl-logo {
//   display: none !important;
// }

.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  &-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    border: none;
    opacity: 0;
  }
}

.map-popup-image {
  width: 250px;
  height: 170px;
  text-align: right;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.map-popup-summary {
  width: 250px;
  padding: 10px;
  &-title {
    font-weight: 600;
  }
  &-button {
    margin-top: 10px;
    cursor: pointer;
  }
  &-duration {
    display: flex;
    gap: 5px;
  }
}

//Mapbox style overwrite
.mapboxgl-popup-content,
.maplibregl-popup-content {
  padding: 5px !important;
  background-color: #fff !important;
  border-radius: 8px !important;
}
.mapboxgl-popup-tip {
  display: block !important;
}
.mapboxgl-popup-close-button {
  background-color: #000000c7 !important;
  padding: 0px 7px 5px 7px;
  margin: 10px;
  border-radius: 4px !important;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
