.scroll-element {
  position: relative;
  scroll-behavior: smooth;
  .scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 40px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border-color: transparent;
    transition: all 0.1s linear;
    &__right {
      right: -20px;
    }
    &__left {
      left: -20px;
    }
    z-index: 100;
  }
  .hidden {
    opacity: 0;
    pointer-events: none;
  }
  .scroll-element-list-scroller {
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 24px;
    background-color: #fff;
    border-radius: 8px;
  }
}
