@import "@Assets/styles/themedStyles.scss";

.ticket-purchase-rows-modal-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 8px;
  box-shadow: inset 0px -2px 0px #c4c4c4;
  gap: 8px;
}
.ticket-purchase-rows-button {
  flex: 1;
  margin: 0 4px;
  max-width: 40%;
}

.ticket-purchase-rows-ticket-date {
  display: flex;
  flex-direction: column;
  font-family: $fontFamilyPrimary;
  font-style: normal;
  font-weight: 600;

  text-align: center;

  color: #000000;
  span.ticket-purchase-rows-date {
    font-size: 36px;
    line-height: 43px;
  }
  span.ticket-purchase-rows-day {
    font-size: 18px;
    line-height: 22px;
  }
}

.ticket-purchase-rows-ticket-time-only {
  margin: 0 auto;
  border-radius: 8px;
  padding: 8px;
  border: 2px solid transparent;
  text-align: center;
  p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.8px;
    color: #868e96;
  }
}

.ticket-purchase-rows-ticket-time {
  margin: 0 auto;
  background-color: #f1f3f5;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  border: 2px solid transparent;
  text-align: center;
  p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.8px;
    color: #000000;
  }
  p.ticket-purchase-rows-duration {
    color: #868e96;
  }
}
.ticket-purchase-rows-ticket-time-selected {
  border: 2px solid #3da9ff;
  border-radius: 8px;
}
