.support-request-modal {
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 20px;
  width: 35vw;

  &__title {
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 768px) {
  .support-request-modal {
    width: 100%;
  }
}
