.linkify-text {
  a {
    color: #000;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-line: underline;
    text-underline-offset: 4px;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
      text-decoration-line: none;
      text-decoration-line: underline;
      text-underline-offset: 4px;
    }
  }
  &-bold {
    font-weight: 600;
  }
}
