.notifications-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.notifications-section-wrapper {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    padding: 0 72px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 24px;
  }

  margin-bottom: 32px;
}

.notifications-section {
  flex: 1;
  height: 100%;

  &__title {
    font-size: 48px;
    font-weight: 400;
    line-height: 58px;
    margin: 55px 0 0;
  }

  &__header {
    display: flex;
    gap: 16px;
    margin: 16px 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > .notification-item {
      border-radius: 8px;
    }
  }

  &__no-notis {
    color: #494747;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 24px;
    border: 1px dashed #b6b6b6;

    &-bell {
      margin-top: -3px;
      margin-right: 5px;
    }
  }

  .notification-item__body {
    margin: 16px 0px;
  }

  .notification-item__header {
    padding-bottom: 0;
  }
}

.notifications-section-near-you {
  padding-bottom: 20px;
  padding-right: 20px;
}
