@import "@Assets/styles/themedStyles.scss";

#user-profile-my-events {
  background: white;
  padding: 10px;
  border-radius: 8px;

  .display-block {
    display: block;
  }

  .events-table-cell-details-clickable {
    text-decoration: underline;
    text-underline-offset: 0.25rem;
    cursor: pointer;
    font-weight: bold;
  }

}