@import "@Assets/styles/themedStyles.scss";

#user-profile-my-memberships {
  background: white;
  padding: 10px;
  border-radius: 8px;

  .display-block {
    display: block;
    margin-top: 3px;
  }

  .events-table-cell-documents {
    font-weight: bold;
    text-decoration: underline;
    color: black;
    text-underline-offset: 0.25rem;
    cursor: pointer;
  }

}