.map-pois {
  display: flex;
  gap: 20px;
  height: 600px;
  &-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  &-list {
    display: block;
    height: 100%;
    &-message {
      text-align: center;
      padding: 40px;
      color: gray;
    }
  }
  &-list-min {
    display: none;
    &-link {
      text-align: right;
      display: block;
      padding: 0px 5px;
    }
    &-message {
      text-align: center;
      padding: 40px;
      color: gray;
    }
  }

  &-default {
    margin-left: 50px;
  }
  &-wide {
    margin-left: 0px;
  }
  &__map__min {
    display: none;
  }
  &__pois {
    flex: 1;
    overflow-y: hidden;
    position: relative;
    &__top {
      display: flex;
      gap: 20px;
      align-items: center;

      &__collapse {
        display: flex;
        border: none;
        background-color: transparent;
        cursor: pointer;
        width: 35px;
        height: 25px;
      }
      &__search {
        flex: 1;
      }
    }
  }
  &__map {
    display: flex;
    flex: 1.5;
    max-width: 1100px;
  }
  &__cards {
    overflow-y: scroll;
    height: 100%;
    margin-top: 10px;
    padding-bottom: 60px;
    &__min {
      display: none;
    }
  }
  &__view {
    font-size: 16px;
    line-height: 19px;
  }

  a {
    color: #000;
    text-underline-offset: 4px;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-underline-offset: 4px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .map-pois {
    flex-direction: column;
    height: 100%;
    &-list {
      display: none;
    }
    &-list-min {
      display: block;
      height: 100%;
    }
    &__map {
      display: none;
    }
    &__map__min {
      display: block;
    }

    &__cards {
      display: none;
      &__min {
        display: block;
      }
    }
    &__pois__top__collapse {
      display: none;
    }
    &-default {
      margin-left: 0;
    }
  }
}
