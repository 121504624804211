.breadcumb-container {
  display: flex;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 17px;
  a {
    color: #000;
    text-decoration: none;
    text-decoration-line: none;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
      text-decoration-line: none;
      text-decoration-line: underline;
      text-underline-offset: 4px;
    }
    text-decoration-line: underline;
    text-underline-offset: 4px;
  }
}

.breadcumb-indicator {
  margin-left: 10px;
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .breadcumb-container {
    flex-wrap: wrap;
    line-height: 27px;
  }
}
