.dropdown-checkbox {
  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  display: inline-block;
  &-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    text-transform: capitalize;
    color: #334150;
  }
  &-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
    color: #000;
    margin: 5px 0;
  }
}
