.date-calendar-select {
  @import "react-datepicker/dist/react-datepicker";
  display: flex;
  position: relative;
  border-color: #c5cee0;
  border-style: solid;
  border-radius: 3px;
  border-width: 1px;

  font-size: 14px;
  height: 48px;
  .date-calendar-select__input {
    padding: 12px 10px 5px 16px;
  }

  input {
    border: none;
    width: 100%;
    cursor: pointer;
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }
  }
  &__icon {
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 0;
    padding: 14px 16px 5px 16px;
  }

  /*    */
}

.date-calendar-select-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #334150;
}

/* Custom Date picker styles - start */
/* 

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100% !important;
} */
/* Custom Date picker styles - end */
