.tenant-subscribe {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 75%;
  justify-self: center;

  &-title {
    font-weight: 600;
    font-size: 20px;
  }

  &-intro {
    text-align: justify;
    font-size: 14px;
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    input::placeholder{
      color: white;
    }

    &-row-1 {
      display: flex;
      gap: 1rem;


      &-item {
        flex: 1;
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    &-row-2 {
      display: flex;
      gap: 10px;

      &-item {
        flex: 2;
      }

      &-button {
        flex: 1;
        margin-top: -2px;
      }

      @media (max-width: 768px) {
        flex-direction: column;
        &-button {
          align-self: flex-end;
        }
      }
    }

    &-error {
      height: 24px;
      color: red;
    }
  }
}