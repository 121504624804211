// TODO: Refactor to follow a naming convention of the styles to be more friendly to read
.event-ticket-purchase {
  width: 100vw;
  display: block;
  max-width: 1350px;
  padding: 0px 10px;
  margin: 0 auto;
  flex-grow: 1;
  &-content {
    display: flex;
    gap: 24px;
  }
}
