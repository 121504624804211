.text-input {
  text-align: left;
  &-field {
    width: 100%;
    border-color: #c5cee0;
    border-style: solid;
    border-radius: 3px;
    border-width: 1px;
    padding: 8px 10px 5px 10px;
    font-size: 14px;
    height: 48px;
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }
  }
  &-loader {
    padding-left: 15px;
    padding-top: 0;
  }
  &-label-row {
    display: flex;
    justify-content: space-between;
  }
  &-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    text-transform: capitalize;
    color: #334150;
  }
  &-margin {
    margin-top: 10px;
  }
  &-error {
    color: #868e96;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-top: 5px;
  }
  &-field-password-wrapper {
    display: flex;
  }
  &-field-password {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
  }
  &-field-password-button {
    border-color: #c5cee0;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0;
    height: 48px;

    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f9bb3;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8f9bb3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8f9bb3;
}
