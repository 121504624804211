.table {
  position: relative;
  border-style: solid;
  border-top-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 0;
  border-color: #dee2e6;

  overflow-x: auto; // Enables horizontal scrolling if needed
  -webkit-overflow-scrolling: touch; // Improves scrolling on iOS
  @media (max-width: 768px) { // Apply only for screens below 768px
    display: block;
    white-space: nowrap; // Prevent text from wrapping inside table cells
  }

  &-controls {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 0.875rem;
    color: #212b36;
    margin-right: 1.25rem;
    padding: 1.25rem;

    overflow-x: auto; // Enables horizontal scrolling if needed
    -webkit-overflow-scrolling: touch; // Improves scrolling on iOS
    @media (max-width: 768px) { // Apply only for screens below 768px
      white-space: nowrap; // Prevent text from wrapping inside table cells
      justify-content: start;
      align-items: start;
    }

    &-paging {
      display: flex;
      align-items: flex-end;
      font-size: 1rem;

      &-text {
        width: 100%;
        white-space: nowrap;
        margin-right: 0.5rem;
      }
    }
  }

  &-loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &-bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: white;
      background-color: rgba(255, 255, 255, 0.75);
    }
  }

  &-header-row {
    display: flex;
    width: 100%;
    min-height: 30px;

    &-ele {
      display: flex;
      font-weight: bold;
      text-transform: uppercase;
      width: 100%;
      padding: 1px;

      &-content {
        overflow-x: scroll;
        padding: 16px 8px;

        &-center {
          margin: auto;
        }

        &-left {
          float: left;
          width: 100%;
        }

        &-sorting {
          padding: 4px 0 !important;
        }
      }

      &-resizer {
        margin-top: auto;
        margin-bottom: auto;
        height: 100%;
        width: 0.5rem;
        float: right;
        cursor: col-resize;
        user-select: none;
        touch-action: none;
        max-height: 60%;
        padding: 16px 8px;

        &-line {
          width: 1px;
          height: 100%;
          margin-left: auto;
          margin-right: auto;

          .group:hover & {
            opacity: 1;
          }
        }
      }
    }
  }

  &-row {
    display: flex;
    width: 100%;
    height: fit-content;
    border-top: 1px solid #dee2e6;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;

    &-link {
      color: #000;
      text-decoration: none;
      margin-top: auto;
      margin-bottom: auto;
      height: fit-content;
      min-height: 45px;

      &-td {
        margin: auto;
        vertical-align: middle;
        font-size: 0.875rem;
        line-height: 1.25rem;
        overflow-x: scroll;
        padding-right: 16px;

        &-left {
          padding-left: 8px;
        }

        & > * {
          font-size: 0.875rem;
        }
      }
    }

    &-highlight {
      transition: all 500ms;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}