.amenities-detail-container {
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 72px 70px 72px;
  input {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }
  }
  button {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }
  }
  .amenities-details-breadcrumb {
    margin: 24px 0;
  }

  .amenities-search-input {
    color: #32373f;
    border: 1px solid #5f666d;
    .search-input-container-icon {
      padding-top: 2px;
    }
  }

  .amenities-top-content {
    display: flex;
    padding: 32px 72px;
    background-color: #ffffff;
    &-left {
      display: flex;
      flex-direction: column;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #465b85;
        margin: 0;
      }
      .amenities-top-content-left-title h1 {
        font-style: normal;
        margin: 0;
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
      }
    }
  }
  .amenities-main-content {
    margin: 0 auto;
    padding: 0 72px;
    margin-bottom: 100px;
    background-color: #ffffff;
    .amenities-card {
      .amenities-card-content {
        padding-top: 48px;
        padding-bottom: 30px;
        display: flex;
        justify-content: space-between;
        &__description {
          max-width: 600px;
          p {
            margin-top: 0;
          }
        }
        &__footer {
          margin-top: 16px;
        }
        &__right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
          .amenities-card-top-actions {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            padding: 0 16px;
            .amenities-details-fav {
              border: none;
              background-color: transparent;
              cursor: pointer;
            }
            .amenities-details-share {
              border: none;
              background-color: transparent;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .amenities-search {
    display: flex;
    flex: 1;
    padding: 5px;
    align-items: center;
    justify-content: flex-end;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .amenities-tags::-webkit-scrollbar {
    display: none;
  }

  .amenities-tags-scroll-button {
    border: none;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    cursor: pointer;
  }

  .amenities-tags-scroll-button-icon {
    color: #323232;
  }

  .amenities-tags {
    scroll-behavior: smooth;
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: scroll;
    padding: 10px;
    -ms-overflow-style: none; /* Hide scrollbar IE and Edge */
    scrollbar-width: none; /* Hide scrollbar Firefox */
  }

  .amenities-select-wrapper {
    display: flex;
    gap: 20px;
  }
}

@media only screen and (max-width: 768px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .amenities-events-row {
    flex-direction: column;
  }
}
