@import "@Assets/styles/themedStyles.scss";

body {
  display: flex;
  margin: 0;
  font-family: $fontFamilyPrimary, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

// List preferred font formats in the following order in the tenant config:
// woff2 -- Best compression, modern browsers
// woff -- Good compression, modern browsers + a little extra
// ttf/otf -- Best support, bad compression, legacy browsers. Only use if you have to.

/** Common classes **/
.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.w-12 {
  width: 3rem;
}

.ml-2 {
  margin-left: .5rem;
}

.h-4 {
  height: 1rem
}

.w-4 {
  width: 1rem
}

.-mt-1 {
  margin-top: -0.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.font-bold {
  font-weight: bold;
}