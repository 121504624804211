.stepper {
  .stepper-steps {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    .stepper-step {
      background-color: #dee2e6;
      width: 15px;
      height: 15px;
      border-radius: 8px;
    }
    .stepper-step-active {
      background-color: #000;
    }
  }

  .stepper-text {
    padding-top: 10px;
    font-size: 14px;
    line-height: 19px;
    color: #000;
  }
}
