.tenant-footer-links {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 1rem;

  &-split {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
