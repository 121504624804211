.tooltip {
  position: relative;
  display: inline-block;
  height: 22px;
  bottom: 5px;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  &.tooltip-left {
    top: -5px;
    right: 105%;
  }

  &.tooltip-top {
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
  }

  &.tooltip-right {
    top: -5px;
    left: 105%;
  }

  &.tooltip-bottom {
    top: 100%;
    left: 50%;
    margin-left: -60px;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
