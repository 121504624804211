.membership-payment {
  width: 100vw;
  display: block;
  max-width: 1350px;
  padding: 0px 10px;
  margin: 0 auto;
  flex-grow: 1;
}

@media only screen and (max-width: 768px) {
}
