.tenant-mobile-app-links {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex-direction: column;

  &-title {
    font-weight: 600;
    font-size: 20px;
  }

  &-images {
    display: flex;
    gap: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}