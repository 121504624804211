.ticket-purchase-modal {
  padding-top: 30px;
  min-width: 379px;
  height: 100vh;
  padding: 24px;
  position: relative;
  .top-right-actions {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    button {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }
  .ticket-purchase-modal-info {
    font-size: 12px;
    text-transform: uppercase;
    box-shadow: inset 0px -2px 0px #c4c4c4;
    padding: 0 8px 8px 8px;
    margin-bottom: 8px;
    &-title {
      font-weight: 600;
    }
    p {
      line-height: 10px;
      letter-spacing: 1px;
    }
  }
  .ticket-purchase-modal-header {
    position: relative;
    display: flex;
    width: 100%;
    box-shadow: inset 0px -2px 0px #c4c4c4;
    &-select {
      cursor: pointer;
      padding: 4px 0;
      min-width: 60px;
    }
  }
  .ticket-purchase-modal-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 24px 0;
    box-shadow: inset 0px -2px 0px #c4c4c4;
  }
}

.ticket-purchase-modal-logo {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
}

.ticket-purchase-modal-text {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
}

.ticket-purchase-modal-buttons {
  display: flex;
  gap: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .ticket-purchase-modal {
    min-width: 90vw;
  }
}
