.tag-bubble {
  display: flex;
  align-items: center;

  &.tag-with-action {
    cursor: pointer;
  }

  .tag-multiple {
    margin-right: 10px;
  }

  .tag-item {
    align-items: center;
    display: flex;
    border: none;
    padding: 5px 20px;
    border-radius: 75px 75px;
    gap: 15px;
  }

  .tag-text {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    white-space: nowrap;
  }

  .tag-text-status {
    font-size: 12px;
  }

  .tag-remove-button {
    border: none;
    background: none;
    display: flex;
  }

  .tag-text.large {
    font-size: 14px;
  }

  .tag-item.large {
    padding: 5px 20px;
  }

  .tag-text.medium {
    font-size: 12px;
  }

  .tag-item.medium {
    padding: 5px 20px;
  }

  .tag-text.small {
    font-size: 10px;
  }

  .tag-item.small {
    padding: 4px 8px;
  }
}
