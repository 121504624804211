.tenant-amenities-status {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 80%;
  line-height: 1.75;
  .amenity-status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .name {
    }

    .status {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
