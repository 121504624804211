@import "@Assets/styles/themedStyles.scss";

.event-details-container {
  width: 100vw;
  display: block;
  max-width: 1350px;
  padding: 0px 10px;
  margin: 0 auto 100px auto;
  flex-grow: 1;
}

.event-details-breadcrumb {
  margin-top: 30px;
  text-align: left;
}

.event-details-navigation-min {
  display: none;
  width: 80px;
  margin: 10px 0px 0px 0;
}

.event-details-detail-wrapper {
  margin-top: 30px;
  background-color: #fff;
  padding: 30px 30px;
  border-radius: 8px;
}

.event-details-location-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  line-height: 1;
  margin: 16px;

  p {
    margin: 0;
  }

  .event-details-location-bottom-text {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
  }
}

.event-details {
  &-text {
    font-size: 14px;
    line-height: 17px;
    color: #32373f;
  }

  &-name {
    font-size: 32px;
    line-height: 38px;
    font-weight: 600;
  }

  &-location {
    margin-top: 5px;
  }
}

.event-details-date {
  padding: 5px 0px;
}

.event-details-pinned {
  margin-left: -3px;
}

.event-details-crousel {
  margin-top: 20px;
}

.event-details-categories {
  display: flex;
  gap: 10px;
}

.event-details-category {
  margin-top: 20px;
}

.event-details-bottom-content {
  display: flex;
  gap: 20px;
  padding-top: 10px;
}

.event-details-description {
  min-height: 50px;
}

.event-details-bottom-content-left {
  flex: 3;
}

.event-details-bottom-content-right {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.event-details-bottom-content-right-logged {
  justify-content: space-between;
}

.event-details-top-actions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;

  // TODO: Sharing will be enabled after launch. Leaving the code commented now
  // .event-details-share {
  //   border: none;
  //   background-color: transparent;
  //   cursor: pointer;
  // }
}

.event-details-fav {
  text-align: right;
  padding-top: 20px;

  &-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.event-details-rsvp {
  min-width: 300px;
}

.event-details-tickets {
  min-width: 300px;

  .event-details-tickets-card {
    display: flex;
    flex-direction: column;
    font-family: $fontFamilyPrimary;
    font-style: normal;
    margin-bottom: 24px;

    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #32373f;
      margin: 0;
      text-align: left;
      padding: 16px;
    }

    &__list {
      display: flex;
      flex-direction: column;
    }

    &__row:not(:last-child) {
      box-shadow: inset 0px -2px 0px #c4c4c4;
    }

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
  }
}

@media only screen and (max-width: 768px) {
  .event-details-detail-wrapper {
    padding: 30px 20px;
    margin-top: 10px;
  }
  .event-details-breadcrumb {
    display: none;
  }
  .event-details-navigation-min {
    display: block;
  }
  .event-details-categories {
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  .event-details-category {
    margin-top: 0;
  }
  .event-details-bottom-content {
    flex-direction: column;
    gap: 0px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .event-details-bottom-content-right {
    flex-direction: column;
    min-height: 90px;
    align-items: center;
  }
  .event-details-tickets {
    border: solid 2px #c4c4c4;
    border-radius: 8px;
    width: 100%;
    padding: 10px 10px 15px 10px;
    margin-top: 20px;
  }
  .event-details-rsvp {
    min-width: 100%;
  }
}
