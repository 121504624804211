.notification-popover {
  &-placeholder {
    cursor: pointer;

    &-modal {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    &-bell {
      height: 25px;
    }

    &-menu {
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }
}