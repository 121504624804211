@import "@Assets/styles/themedStyles.scss";

//TODO: Needs to be refactored to use the scss selector & and the component name as parent, with child selectors.
.resident-center-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 70px;
  font-family: $fontFamilyPrimary;
  input {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }
  }
  button {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }
  }

  .resident-center-search-input {
    color: #32373f;
    border: 1px solid #5f666d;
    .search-input-container-icon {
      padding-top: 2px;
    }
  }

  .resident-center-main-content {
    margin: 0 auto;
    padding: 48px 72px 0 72px;
    margin-bottom: 100px;
    background-color: #ffffff;
    .AccordionRoot {
      button,
      h3 {
        all: unset;
      }
      border-radius: 6px;
      width: 300px;
      display: flex;
      flex-direction: column;
      width: 100%;
      .AccordionItem:last-child {
        border-bottom: 1px solid #000000;
      }
      .AccordionItem {
        border: none;
        box-shadow: none;
        border-top: 1px solid #000000;
        color: #334150;
        h3 {
          display: flex;
          align-items: center;
          svg {
            margin: 16px;
          }
        }
        .provider-logo {
          margin: 16px;
          max-width: 50px;
          max-height: 50px;
        }
        .AccordionTrigger {
          display: flex;
          width: 100%;
          cursor: pointer;
          padding-top: 8px;
          span {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;

            margin-left: 32px;
            text-transform: uppercase;
          }
          .chevron-icon {
            margin-left: auto;
            margin-right: 16px;
          }
        }
        .AccordionContentText {
          padding-left: 125px;
          padding-bottom: 36px;
          .detail-container {
            .detail-title {
              font-weight: 700;
              padding: 10px 0;
            }
            .detail-information {
              display: flex;
              font-size: 14px;
              line-height: 17px;
              .resident-center-information-item {
                flex: 1;
                padding: 5px 0 20px 0;
              }
              .resident-center-information-item-last {
                flex: 1.5;
                padding: 5px 0 20px 0;
              }
              .label {
                font-weight: 700;
              }
              .url {
                text-decoration: none;
                color: #334150;
              }
              .email {
                text-decoration: none;
                color: #334150;
              }
            }
          }
        }
      }
    }
  }

  .resident-center-search {
    display: flex;
    flex: 1;
    padding: 5px;
    align-items: center;
    justify-content: flex-end;
  }

  .resident-center-select-wrapper {
    display: flex;
    gap: 20px;
  }

  .resident-center {
    &-top-content {
      display: flex;
      padding: 32px 72px;
      background-color: #eeeeee;
      &-left {
        &-title {
          font-style: normal;
          font-weight: 400;
          font-size: 48px;
          line-height: 58px;
          margin: 0;
        }
      }
    }
    &-card {
      &__title {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        margin: 10px 0 30px 0;
      }
    }
    &-services {
      padding: 30px 0 0 0;
      &-name {
        font-size: 32px;
        line-height: 38px;
        padding: 35px 0 15px 0;
      }
      &-item {
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        &-image {
          width: 195px;
          height: 187px;
          object-fit: cover;
        }
        &-title {
          font-size: 20px;
          line-height: 24px;
          padding: 10px 0 0 0;
          text-align: left;
          &-link {
            color: #000;

            text-decoration-line: underline;
            text-underline-offset: 4px;
          }
        }
      }
      &-no-padding {
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .resident-center-container {
    .resident-center {
      &-card {
        &__title {
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 28px;
          margin: 10px 0;
        }
      }
      &-services {
        padding: 30px 0 0 0;
        &-name {
          font-size: 22px;
          line-height: 28px;
          padding: 10px 0 0 0;
          font-weight: 600;
        }
        &-item {
          &-title {
            font-size: 17px;
            line-height: 24px;
          }
        }
      }
      &-top-content {
        flex-direction: column;
        padding: 10px;
        &-left-title {
          font-size: 38px;
          text-align: center;
        }
      }

      &-search {
        justify-content: center;
      }
      &-main-content {
        padding: 15px;
      }
      &-main-content .AccordionRoot .AccordionItem {
        .provider-logo {
          margin: 5px;
        }
        h3 {
          svg {
            margin: 5px;
          }
        }
        .AccordionContentText {
          padding-left: 110px;
          display: flex;
          flex-direction: column;
          gap: 30px;
          .detail-container {
            .detail-title {
              margin-bottom: 10px;
              padding: 0;
            }
          }
          .detail-container .detail-information {
            flex-direction: column;
            .resident-center-information-item {
              padding: 0;
            }
            .resident-center-information-item-last {
              padding: 0;
            }
            gap: 10px;
          }
        }
      }
    }

    &-mobile-embed {
      margin-bottom: 0;
      min-height: 100vh;
      height: 100%;
      justify-content: flex-start;
    }
  }
}
