@import "@Assets/styles/themedStyles.scss";

.image-carousel-slide {
  height: 520px;
  transition: all 0.5s ease;
  background-size: cover;
  background-position: 50%;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 100%;
  padding: 80px 0px 60px 80px;
  align-items: flex-start;
  justify-content: flex-end;
  &-default-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
  }
  &-default {
    padding: 30px 60px;
    background: rgba($imageCarouselTitleBackgroundColor, 0.7);
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &-default-title {
    font-weight: $imageCarouselTitleTextFontWeight;
    font-size: $imageCarouselTitleTextFontSize;
    color: $imageCarouselTitleTextColor;
    line-height: $imageCarouselTitleTextLineHeight;
    max-width: 50vw;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    font-family: $fontFamilySecondary, $fontFamilyPrimary;
  }
  &-default-subtitle {
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    padding-right: 10px;
    color: $imageCarouselSubtitleTextColor;
  }
  &-image-default {
    height: 600px;
    background-position: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &-dots {
    display: flex;
    gap: 20px;
    justify-content: center;
  }

  &-dot {
    border: none;
    background-color: #4a4a4a;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    &-active {
      background-color: #e08550;
    }
  }
}

@media only screen and (max-width: 768px) {
  .image-carousel-slide {
    padding-bottom: 30px;
    &-image-default {
      max-height: 180px;
      background-size: 100%;
      background-repeat: no-repeat;
    }
    &-default {
      padding: 20px 20px;
    }
    &-default-title {
      font-size: $imageCarouselTitleTextMinFontSize;
      line-height: $imageCarouselTitleTextMinLineHeight;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      max-width: 100vw;
      overflow: hidden;
      // text-align: right;
    }
    &-default-subtitle {
      margin-top: 5px;
      font-size: 15px;
      line-height: 17px;
      text-align: center;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &-dot {
      width: 12px;
      height: 12px;
    }
  }
}
