.resident-center-details {
  padding: 10px 70px;
  &-breadcrumb {
    padding: 20px 0 30px 0px;
  }
  &-back {
    display: none;
    width: 80px;
    margin: 0px 0px 10px 0;
  }
  &-content {
    padding: 30px;
    background-color: #fff;
    border-radius: 8px;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    &-type {
      color: #465b85;
    }
    &-title {
      font-size: 32px;
      line-height: 38px;
      font-weight: 600;
    }
    &-dates {
      color: #32373f;
    }
    &-description {
      padding: 40px 0 20px 0;
      color: #32373f;
    }
  }
}

@media only screen and (max-width: 768px) {
  .resident-center-details {
    padding: 10px 20px;
    &-breadcrumb {
      display: none;
    }
    &-back {
      display: block;
    }
  }
}
