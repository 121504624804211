.search-input {
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #c5cee0;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  color: #878686;
  max-height: 41px;
  display: flex;
  &-icon {
    position: absolute;
    top: calc(100% - 27px);
  }
  &-input {
    border: none;
    color: #878686;
    width: 100%;
    background-color: #fff;
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }
  }
  &-settings {
    margin-top: 5px;
    border: none;
    background-color: transparent;
  }
  &-menu {
    position: absolute;
    z-index: 300;
    min-width: 280px;
    right: 5px;
    top: 100%;
    margin-top: 5px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 30px 30px 10px 30px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    max-height: 400px;
    overflow: auto;
    &-hidden {
      display: none;
    }
    &-title {
      margin-bottom: 20px;
    }
    &-section {
      &-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
      }
      &-options {
        padding-left: 0px;
        &-option {
          list-style: none;
          font-size: 14px;
          line-height: 17px;
          padding-bottom: 15px;
        }
      }
    }
  }
}

input[type="search"] {
  -moz-appearance: none; /* older firefox */
  -webkit-appearance: none; /* safari, chrome, edge and ie mobile */
  appearance: none; /* rest */
  padding-left: 27px;
}

input[type="search"]::placeholder {
  color: #dee2e6;
}
