.user-type-info-modal {
  padding: 30px;
  max-width: 450px;
  &-title {
    font-weight: bold;
  }
  &-text {
    text-align: justify;
  }
}


@media only screen and (max-width: 768px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .user-type-info-modal {
    min-width: 90vw;
  }
}