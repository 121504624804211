.enhanced-3-column-footer-template {
  padding: 60px 40px 0 40px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-column-gap: 25px;
  align-items: start;
  grid-template-areas:
    "tenant-logo tenant-subscribe tenant-amenities-status"
    "tenant-footer-links tenant-subscribe tenant-amenities-status"
    "tenant-address tenant-subscribe tenant-mobile-app-links"
    "tenant-copyright tenant-subscribe tenant-mobile-app-links";

  .tenant-logo {
    grid-area: tenant-logo;
    justify-content: left;
  }

  .tenant-address {
    grid-area: tenant-address;
  }

  .tenant-copyright {
    grid-area: tenant-copyright;
  }

  .tenant-subscribe {
    grid-area: tenant-subscribe;
  }

  .tenant-footer-links {
    grid-area: tenant-footer-links;
  }

  .tenant-amenities-status {
    grid-area: tenant-amenities-status;
  }

  .tenant-mobile-app-links {
    grid-area: tenant-mobile-app-links;
  }

  @media (max-width: 768px) {
    padding: 20px;
    grid-template-columns: 1fr;
    grid-row-gap: 25px;
    grid-template-areas:
      "tenant-logo"
      "tenant-subscribe"
      "tenant-footer-links"
      "tenant-amenities-status"
      "tenant-mobile-app-links"
      "tenant-address"
      "tenant-copyright"
      "version-number";
  }
}