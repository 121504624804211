.poi-detail {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 600px;
  &-image {
    width: 600px;
    background-color: #d9d9d9;
    position: relative;
    &-close {
      position: absolute;
      top: 0;
      right: 0px;
    }
  }
  &-content {
    overflow-y: auto;
    &-description {
      font-size: 16px;
      line-height: 19px;
      color: #605e5e;
      padding: 20px;
      &-top {
        display: flex;
        justify-content: space-between;
        &-title {
          font-size: 20px;
          line-height: 24px;
          font-weight: 600;
          display: flex;
          gap: 20px;
          align-items: center;
        }
        &-fav {
          border: none;
          background-color: transparent;
          cursor: pointer;
        }
      }
      &-contact {
        &-section {
          display: flex;
          gap: 20px;
          align-items: center;
          padding-top: 15px;
        }
        &-location {
          margin-top: 5px;
        }
      }

      &-html {
        color: #000;
        padding: 20px 0px;
      }
    }
    &-cta {
      padding: 0px 20px 20px 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .poi-detail-image {
    min-width: 90vw;
  }
}
