@import "@Assets/styles/themedStyles.scss";

.home-container {
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
}

.home-section-image {
  position: relative;
}

.home-upcoming-events {
  padding: 50px 60px 0px 60px;
}

.home-section-map {
  padding: 40px 85px 20px 85px;
}

.home-section-title {
  font-family: $fontFamilySecondary, $fontFamilyPrimary;
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 48px;
  margin: 30px 20px 20px 20px;
  color: $homeSectionTitleColor;
  border-bottom: solid $defaultSecondaryColor 2px;
}

.home-section-title-min {
  font-size: 32px;
}

.home-section-title-position {
  margin: 40px 20px 10px 90px;
  border-bottom: none;
}

.home-section-title-position-right {
  justify-content: flex-end;
  padding-right: 20px;
  margin-left: 9vw;
}

.home-places {
  margin: 40px 0px 70px 0px;
  background-color: #fff;
  padding: 40px 60px;
}

.home-places-cards {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  margin: 0px 40px;
  padding: 40px 100px 50px 100px;
  cursor: pointer;
}

.home-places-card-image {
  flex: 1;
  width: 194px;
  height: 168px;
  background-color: #fff;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

.home-places-card-text {
  font-size: 20px;
  line-height: 24px;
  padding: 20px 0px;
}

iframe>html {
  opacity: 0.4;
}

.home-sponsors-wrapper {
  padding: 50px 0px;
}

.home-sponsors {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;

  &_image {
    background-size: contain;
    background-position: 50%;
    transition: all 0.5s ease;
    background-size: cover;
    width: 380px;
    height: 100px;
  }
}

.home-sponsors-min {
  padding: 0 20px;
  display: none;
}

.home-map {
  height: 516px;
  border-top: solid $defaultPrimaryColor 1px;
  border-bottom: solid $defaultPrimaryColor 1px;
}

.home-map-min {
  display: none;
}

.home-section-link {
  text-decoration: none;
  text-decoration-line: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    text-decoration-line: none;
  }
}

@media only screen and (max-width: 768px) {
  .home-sponsors {
    display: none;
  }

  .home-upcoming-events {
    padding: 5px 20px 10px 20px;
  }

  .home-sponsors-min {
    display: block;
  }

  .home-section-title {
    font-size: $homeSectionTitleMinFontSize;
    line-height: $homeSectionTitleMinLineHeight;
    margin-left: 10px;
    margin: 20px 0 15px 0;
  }

  .home-section-title-position {
    margin: 20px 20px 10px 20px;
  }

  .home-section-title-position>svg {
    width: 13px;
  }

  .home-section-title-position-right {
    justify-content: flex-start;
    text-align: center;
    display: block;
    text-align: center;
    padding: 0px 0px;
  }

  .home-section-map-min {
    padding: 0 20px;
  }

  .home-map {
    display: none;
  }

  .home-map-min {
    display: block;
    margin: 0;
    padding: 0;
  }

  .home-places {
    padding: 5px 20px;
    margin: 20px 0px 0px 0px;
    background-color: #fff;
  }

  .home-section-title-position-right {
    margin-bottom: 15px;
    text-align: left;
    margin-left: 0;
  }

  .home-places-cards {
    overflow: scroll;
    justify-content: flex-start;
    padding: 0px;
    margin: 0;
    gap: 20px;
  }

  .home-places-card-image {
    width: 200px;
    height: 200px;
  }

  .home-section-title-min {
    margin-top: 40px;
  }
}