.shadcn-button {

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem; // Equivalent to gap-2
  white-space: nowrap;
  font-size: 0.875rem; // Equivalent to text-sm
  font-weight: 500; // Equivalent to font-medium
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  // New styles
  height: 2.5rem; // Equivalent to h-10
  padding-left: 1rem; // Equivalent to px-4
  padding-right: 1rem; // Equivalent to px-4
  padding-top: 0.5rem; // Equivalent to py-2
  padding-bottom: 0.5rem; // Equivalent to py-2
  border-radius: 0.375rem;

  cursor: pointer;

  &-primary, &-default {
    background-color: black;
    color: white;

    &:hover {
      background-color: #222222; // Equivalent to hover:bg-slate-100
    }
  }

  &-ghost {
    background-color: white;
    border: 0px;

    &:hover {
      background-color: #f1f5f9; // Equivalent to hover:bg-slate-100
      color: #0f172a; // Equivalent to hover:text-slate-900
    }
  }

  &:focus-visible {
    outline: none;
    ring-width: 2px;
    ring-color: #020617; // Equivalent to slate-950
    ring-offset-width: 2px;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  svg {
    pointer-events: none;
    width: 1rem; // Equivalent to size-4
    height: 1rem; // Equivalent to size-4
    flex-shrink: 0;
  }
}