.membership-dog-terms {
  padding: 40px;
  &-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
  }
  &-description {
    font-size: 16px;
    line-height: 19px;
  }
  &-checkbox {
    margin-top: 40px;
  }
  &-proceed {
    margin-top: 20px;
  }
}
