@import "@Assets/styles/themedStyles.scss";

.card-header__close {
  width: 100%;
}

.card-content {
  .interest-list {
    display: flex;
    gap: 16px;
  }
}

.card {
  background: #ffffff;
  border-radius: 8px;
  padding: 32px;
  &-title {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    color: #000000;
  }
  .card-header {
    display: flex;
    gap: 22px;
    &__editButton {
      height: fit-content;
      background: none;
      color: #465b85;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      text-decoration: underline #465b85;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.edit-profile-modal {
  width: 946px;
  button .button-content .button-content-text {
    margin-top: 0;
  }
  .card {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 8px;
    padding: 16px 32px 24px 32px;
    gap: 16px;
    &-title {
      font-family: $fontFamilyPrimary;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
      margin: 0;
    }
    .text-input-label {
      color: #334150;
    }
    .date-calendar-container {
      margin-top: 10px;
      .date-calendar-select {
      }
      .date-calendar-select-label {
        display: flex;
      }
    }
    .card-header {
      display: flex;

      &__close {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .button-close.button {
          width: 32px;
          height: 32px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .card-content {
      display: flex;
      flex-direction: column;
      gap: 22px;
      &-row {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        .bottom-text {
          letter-spacing: 1px;
          color: #32373f;
          margin-top: -6px;
        }
        .label-with-value-text {
          display: inline-flex;
          .label-with-value-bolder {
            font-size: 14px;
            font-weight: 600;
          }
        }
        .spacer {
          flex-grow: 1;
        }

        & > p {
          margin: 0;
          line-height: 16px;
          font-family: $fontFamilyPrimary;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #32373f;

          a {
            color: #465b85;
            text-decoration: underline #465b85;
          }
        }
        .text-input-field {
          width: 100%;
          border-color: #c5cee0;
          border-style: solid;
          border-radius: 3px;
          border-width: 1px;
          padding: 3px 10px 5px 16px;
          font-size: 14px;
          height: 48px;
        }
      }
      .input-row > div {
        width: 100%;
      }
      .edit-profile-avatar {
        display: flex;
        flex-direction: column;
        gap: 16px;
        &__editButton {
          flex-grow: 0;
          display: inline-flex;
          height: fit-content;
          background: none;
          color: #465b85;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
          text-decoration: underline #465b85;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    .card-footer {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      .button {
        font-family: $fontFamilyPrimary;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #32373f;
        padding: 16px 24px;
        height: 56px;
        &.button-primary {
          color: #e9ecef;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .card-content {
    &-row {
      flex-direction: column;
      .edit-profile-avatar {
        align-items: center;
        justify-content: center;
        img {
          width: 80px;
        }
      }
      .spacer {
        display: none;
      }
    }
  }
}
