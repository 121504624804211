.stripe-payments-error {
  display: flex;
  gap: 10px;
  background-color: #ffe5e5;
  padding: 15px;
  border-radius: 8px;
  align-items: center;
  &__text {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
  }
  animation: horizontal-shaking 0.35s 0.35s;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
