.membership-modal {
  &-top {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 40px 40px 0px 40px;
    gap: 10px;
  }
}
