.pois-list {
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0px 150px 0px;
  height: 100%;
  overflow: scroll;
}

@media only screen and (max-width: 768px) {
  .pois-list-view-more {
    padding: 20px 0px 20px 0px;
  }
}
