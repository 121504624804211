@import "@Assets/styles/themedStyles.scss";

.contact-us {
  a {
    text-decoration-line: underline;
    text-underline-offset: 4px;
    color: #000;
  }

  &__breadcrumb {
    margin: 0 50px;
    padding: 30px 0px;
  }

  &__back {
    display: none;
    width: 80px;
    margin: 0px 0px 10px 0;
  }

  &__detail {
    display: flex;
    gap: 20px;
    margin: 0 0 80px 0;
    background-color: #fff;
    font-size: 14px;
    line-height: 21px;

    &__left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 70px;

      &__title {
        font-size: $cmsDetailTitleFontSize;
        line-height: 38px;
        font-weight: $cmsDetailTitleFontWeight;
        color: $defaultPrimaryColor;
        font-family: $fontFamilySecondary, $fontFamilyPrimary;
      }

      &__description {
        margin-top: 50px;
      }
    }

    &__right {
      flex: 1;
      height: 630px;
      background-size: cover;
    }
  }

  &__more {
    background-color: #fff;
    padding: 70px;
    margin: 70px, 50px;

    &__title {
      font-size: $cmsDetailTitleFontSize;
      line-height: 38px;
      font-weight: $cmsDetailTitleFontWeight;
      color: $defaultPrimaryColor;
      font-family: $fontFamilySecondary, $fontFamilyPrimary;
    }

    &__accordion {
      margin-top: 40px;

      &__item {
        border-top: 2px solid $defaultSecondaryColor;
        padding-left: 10px;

        h3 {
          display: flex;
          justify-content: space-between;
        }

        &__header {
          color: #334150;
          text-transform: uppercase;

          &__trigger {
            border: none;
            background-color: transparent;

            .fa-chevron-up {
              display: none;
            }
          }

          &__trigger[aria-expanded="true"] {
            .fa-chevron-up {
              display: block;
            }

            .fa-chevron-down {
              display: none;
            }
          }
        }
      }

      &__content {
        padding-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .contact-us {
    &__breadcrumb {
      display: none;
    }

    &__back {
      display: block;
    }

    &__detail {
      margin: 20px 0px;
      flex-direction: column;

      &__left {
        padding: 40px;
      }

      &__right {
        min-width: 100vw;
        min-height: 300px;
      }
    }

    &__more {
      background-color: #fff;
      padding: 40px 20px;
      margin-bottom: 20px;
    }
  }
}