.placeholder {
  &-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &-image {
    height: 180px;
    width: 310px;
  }

  &-title {
    height: 24px;
    margin-top: 20px;
    width: 100px;
  }
}

/* Place holder animation */
.image-placeholder {
  background: linear-gradient(-45deg, #ccc, #eee);
  background-size: 400% 400%;
  animation: gradient 3s ease-in-out infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}