.membership-dog-row {
  display: flex;
  gap: 20px;
  &-input {
    flex: 1;
    &-remove {
      display: flex;
      align-items: center;
      padding: 30px 10px 0px 0px;
      border: none;
      background-color: transparent;
      &-min {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .membership-dog-row {
    flex-direction: column;
    gap: 0px;
    border-bottom: 1px solid silver;
    padding-bottom: 20px;
    &-input {
      width: 100%;
      padding: 10px 0 0 0;
      &-remove {
        display: none;
        &-min {
          display: flex;
          justify-content: flex-end;
          top: 0px;
          right: 0;
        }
      }
    }
  }
}
