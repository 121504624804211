@import "@Assets/styles/themedStyles.scss";

.cms-actions {
  padding: 50px 50px 0 50px;

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-evenly;
    gap: 10px;
  }

  &__action {
    padding: 20px;
    min-width: 200px;
    text-align: center;
    color: #000;
    text-decoration-line: none;
    cursor: pointer;

    &:hover {
      border-radius: 30px;
      background-color: #fff;
    }
  }

  &__text {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .cms-actions {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
    padding: 0;
    margin-top: 1rem;

    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 0;
    }

    &__text {
      margin-left: 4vw;
      margin-top: .4rem;
      white-space: nowrap;
    }

    &__action {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      background-color: #fff;
      height: 10vh;
      max-height: 200px;
      border-bottom: 1px solid $defaultPrimaryColor;

      &:hover {
        border-radius: 0;
      }

      svg {
        scale: 70%;
      }
    }

    &__action:last-child {
      border: none;
    }
  }
}