@import "@Assets/styles/themedStyles.scss";

.news-and-articles {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  &__header {
    font-size: 64px;
    line-height: 80px;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: $fontFamilySecondary, $fontFamilyPrimary;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 20px;
  }
  &__card {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 10px 20px;
    border-radius: 10px;
    background-color: white;
    h2 {
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      margin: .5rem 0 0 0;
    }
    &__date {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #605E5E;
    }
    div {
      margin-top: 8px;
    }
    &__read-more {
      cursor: pointer;
      color: $defaultSecondaryColor;
      width: fit-content;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}