.dev-menu {
  min-width: 400px;
  min-height: 300px;
  &-close {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  &-section {
    padding: 0px 20px 20px 20px;
    border-radius: 4px;
    &-row {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-end;
    }
  }
  &-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #334150;
    padding: 10px 0px 5px 0px;
    text-transform: uppercase;
  }
  &-text-area {
    width: 600px;
    height: 200px;
    font-size: 12px;
    border-color: #c5cee0;
    border-radius: 4px;
  }
}
