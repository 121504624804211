.membership-dog-form {
  padding: 40px 40px 40px 40px;
  min-width: 60vw;
  font-size: 16px;
  line-height: 19px;
  overflow: scroll;
  &-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
  }
  &-subtitle {
    padding-top: 10px;
  }
  &-add {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0px 0px 0px;
    &-cta {
      display: flex;
      gap: 10px;
      border: none;
      background-color: transparent;
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      text-decoration-line: underline;
      text-underline-offset: 4px;
      align-items: center;
      svg {
        margin-bottom: 5px;
      }
    }
  }
  &-membership-type {
    padding: 20px 0px;
  }
  &-continue {
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: 20px 0;
  }
}
