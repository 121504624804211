.generic-details {
  width: 100vw;
  display: block;
  max-width: 1440px;
  padding: 0px 50px;
  margin: 0 auto 100px auto;
  flex-grow: 1;

  &-breadcrumb {
    padding: 30px 0px;
  }
  &-back {
    display: none;
    width: 80px;
    margin: 0px 0px 10px 0;
  }
  &-content {
    padding: 30px 30px 50px 30px;
    background-color: #fff;
    border-radius: 8px;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    &-type {
      color: #465b85;
    }
    &-title {
      font-size: 32px;
      line-height: 38px;
      font-weight: 600;
    }
    &-dates {
      color: #32373f;
    }
    &-images {
      padding-top: 20px;
    }
    &-description {
      padding: 10px 0 20px 0;
      color: #32373f;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      * {
        margin: 0;
      }

      &-tags {
        display: flex;
        gap: 10px;
      }
      &-location {
        padding: 0 0 20px 0;
        display: flex;
        flex-direction: column;
        gap: 6px;
        span {
          font-weight: 600;
        }
        p {
          margin: 0;
          text-decoration-line: underline;
          text-underline-offset: 4px
        }
      }
      &-rules {
        margin-top: 30px;
        color: #32373f;
        text-decoration-line: underline;
        text-underline-offset: 4px;
        cursor: default;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-weight: 600;
        width: 150px;
      }
    }
    &-bottom {
      display: flex;
      padding-top: 20px;
      gap: 100px;
      &-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &-right {
        width: 400px;
        &-fav {
          min-height: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          &-button {
            padding-top: 10px;
            border: none;
            background-color: transparent;
            cursor: pointer;
            min-height: 35px;
          }
        }
      }
      &-subscription {
        margin-bottom: 24px;
        &__title {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #32373f;
          padding: 16px;
        }
        &__list {
          display: flex;
          flex-direction: column;
        }
        &__row:not(:last-child) {
          box-shadow: inset 0px -2px 0px #c4c4c4;
        }
        &__row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 10px 16px;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .generic-details {
    padding: 10px 20px 20px 20px;
    &-embed {
      margin: 0 auto;
    }
    &-breadcrumb {
      display: none;
    }
    &-back {
      display: block;
    }
    &-content {
      padding: 15px 30px 30px 30px;
      &-title {
        font-size: 25px;
        font-weight: 600;
      }
      &-images {
        padding-top: 0px;
      }
      &-description {
        display: inline-block;
      }
      &-bottom {
        flex-direction: column;
        gap: 30px;
        padding-top: 0;
        &-right {
          width: 100%;
          &-fav {
            &-button {
              padding-top: 20px;
            }
          }
        }
        &-subscription {
          &__title {
            padding: 15px 5px;
          }
          &__row {
            padding: 10px 5px;
          }
        }
      }
    }
  }
}
