.resend-email-modal {
  padding: 30px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
}

.resend-email-modal-text {
  font-size: 20px;
  line-height: 24px;
}

.resend-email-modal-title {
  font-weight: 600;
}

.resend-email-modal-link-wrapper {
  text-align: center;
}

.resend-email-modal-link {
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  padding: 0;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}
