@import "@Assets/styles/themedStyles.scss";

.button {
  width: 100%;
  padding: 16px 20px;
  border-style: solid;
  border-radius: 8px;
  border-width: 1px;
  font-weight: bold;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  font-size: 14px;
  cursor: pointer;
}

.button-content {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 22px;
}

.button-content-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-content-loader {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.4);
  position: absolute;
}

.button-content-text {
  margin-top: 3px;
}

.button-primary {
  border-color: $defaultPrimaryColor;
  background-color: $defaultPrimaryColor;
  color: #fff;
}

.button-secondary {
  border-color: $defaultPrimaryColor;
  background-color: #fff;
  color: $defaultPrimaryColor;
}

.button-disabled {
  opacity: 0.5;
  cursor: default;
}

.button-close {
  cursor: pointer;
  width: 60px;
  align-self: flex-end;
  background-color: transparent;
  text-align: right;
  border: none;
}
