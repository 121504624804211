.waivers-modal {
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 20px;
  width: 65vw;
  &__title {
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }
  &__cta {
    padding: 20px 0 30px 0;
  }
}

@media only screen and (max-width: 768px) {
  .waivers-modal {
    width: 100%;
  }
}
