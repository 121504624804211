//TODO: Correct class names to follow the pattern. ComponentName-... Wrap child classes inside the parent and use &.
.venues-detail-container {
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1440px;
  align-items: center;
  display: block;
  width: 100%;
  padding: 10px 50px;
  input {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }
  }
  button {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }
  }
  .venues-details-breadcrumb {
    margin: 24px 0;
  }

  .venues-search-input {
    color: #32373f;
    border: 1px solid #5f666d;
    .search-input-container-icon {
      padding-top: 2px;
    }
  }

  .venues-top-content {
    display: flex;
    padding: 32px 72px;
    background-color: #ffffff;
    &-left {
      display: flex;
      flex-direction: column;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #465b85;
        margin: 0;
      }
      .venues-top-content-left-title h1 {
        font-style: normal;
        margin: 0;
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
      }
    }
  }
  .venues-main-content {
    margin: 0 auto;
    padding: 0 72px;
    margin-bottom: 100px;
    background-color: #ffffff;
    .venues-card {
      .venues-card-content {
        padding-top: 48px;
        padding-bottom: 30px;
        display: flex;
        justify-content: space-between;
        &__description {
          max-width: 600px;
          p {
            margin-top: 0;
          }
        }
        &__footer {
          margin-top: 16px;
        }
        &__right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
          .venues-card-top-actions {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            padding: 0 16px;
            .venues-details-fav {
              border: none;
              background-color: transparent;
              cursor: pointer;
            }
            .venues-details-share {
              border: none;
              background-color: transparent;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .venues-search {
    display: flex;
    flex: 1;
    padding: 5px;
    align-items: center;
    justify-content: flex-end;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .venues-tags::-webkit-scrollbar {
    display: none;
  }

  .venues-tags-scroll-button {
    border: none;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    cursor: pointer;
  }

  .venues-tags-scroll-button-icon {
    color: #323232;
  }

  .venues-tags {
    scroll-behavior: smooth;
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: scroll;
    padding: 10px;
    -ms-overflow-style: none; /* Hide scrollbar IE and Edge */
    scrollbar-width: none; /* Hide scrollbar Firefox */
  }

  .venues-select-wrapper {
    display: flex;
    gap: 20px;
  }
}

.venue-detail {
  &-breadcrumb {
    padding: 20px 0px 20px 0px;
  }
  &-back {
    display: none;
    width: 80px;
    margin: 10px 0px 10px 0px;
  }
}

.venues-detail-booking {
  padding: 20px 20px 50px 20px;
  margin: 10px 0px;
  background-color: #fff;
  border-radius: 8px;
  &-content {
    display: flex;
    width: 100%;
    gap: 20px;
    &-images {
      width: 380px;
    }
    &-rates {
      display: flex;
      flex-grow: 1;
      gap: 20px;
      &-calendar {
        flex: 1;
      }
      &-dropdowns {
        display: flex;
        flex-direction: column;
        flex: 1;

        gap: 10px;
        margin-top: 70px;
      }
    }
  }
  &-actions {
    min-width: 190px;
    align-self: flex-end;
    &-rules {
      padding: 20px 5px 0 0;
      text-align: right;
      color: #32373f;
      text-decoration-line: underline;
      text-underline-offset: 4px;
      cursor: default;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-weight: 600;
    }
  }
}

.venue-detail-card-wrapper {
  padding: 10px 0px;
  .venue-detail-card {
    padding: 30px;
    background-color: #fff;
    border-radius: 8px;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    &-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
      margin: 0 0 0 40px;
      &-title {
        font-size: 32px;
        line-height: 38px;
        font-weight: 600;
        padding-top: 20px;
      }
      &-description {
        padding: 10px 0 20px 0;
        color: #32373f;
        flex: 1;
        align-items: center;
        &-tags {
          display: flex;
          gap: 10px;
        }
      }
      &-images {
        width: 295px;
      }
    }
  }
}

.venue-detail-booking-payment {
  max-width: 1350px;
  padding: 0px 5px;
  margin: 0 auto;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media only screen and (max-width: 768px) {
  .venue-detail {
    &-breadcrumb {
      display: none;
    }
    &-back {
      display: block;
    }
    &-card-wrapper {
      padding: 0;
      .venue-detail-card {
        padding: 10px;
        flex-direction: column;
        &-content {
          margin: 0;
          padding: 10px 10px;
          flex-direction: column;
          &-title {
            font-size: 23px;
            line-height: 27px;
          }
          &-images {
            width: 100%;
          }
        }
      }
    }
  }
  .venues-detail {
    &-container {
      padding: 0px 20px;
    }
    &-booking {
      &-container {
        padding: 10px;
        margin: 10px 0px;
        padding-bottom: 30px;
      }
      &-content {
        flex-direction: column;
        &-images {
          display: none;
        }
        &-rates {
          flex-direction: column;
          &-dropdowns {
            width: 100%;
            padding: 0px 0 40px 0;
            margin-top: 0;
          }
        }
      }
      &-actions {
        max-width: none;
        margin: 0;
      }
    }
  }

  .venues-events-row {
    flex-direction: column;
  }
  .venue-detail-booking-payment {
    margin: 0;
  }
}
