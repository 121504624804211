@import "@aws-amplify/ui-react/styles.css";
@import "@Assets/styles/themedStyles.scss";

/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap"); */
:root {
  --amplify-fonts-default-variable: $fontFamilyPrimaryBold, $fontFamilyPrimary;
  --amplify-fonts-default-static: $fontFamilyPrimaryBold, $fontFamilyPrimary;
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);
  --amplify-components-field-label-color: "#ccc";
  --amplify-components-field-large-font-size: 5px;

  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
  --amplify-components-authenticator-form-padding: 0px;

  --amplify-components-fieldcontrol-focus-box-shadow: none;
  --amplify-components-authenticator-router-background-color: #fff;

  --amplify-components-button-link-hover-background-color: transparent;
  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-active-color: #e9ecef;
  --amplify-components-button-link-color: #e9ecef;

  --amplify-components-field-gap: 0px;
  --amplify-components-flex-gap: 10px;

  //Error message (for example, incorrect username/password)
  --amplify-components-alert-error-background-color: #ffe5e5;
  // --amplify-components-alert-error-color: #32373f;
  --amplify-components-alert-padding-block: 10px;
  --amplify-components-alert-padding-inline: 20px;
}

/* TODO: Custom CSS.. */
[data-amplify-container] {
  max-width: 25vw;
  min-width: 300px;
  font-family: $fontFamilyPrimary;
}

.amplify-flex {
  text-align: left;
}

.amplify-label {
  color: #334150;
  font-size: 14px;
  line-height: 23px;
  font-weight: 600;
}

.amplify-input {
  &:hover,
  &:focus,
  &:active {
    border-color: #c5cee0;
    border-radius: 3px;
    border-width: 1px;
  }
}

.amplify-input[name="username"] {
  border-color: #c5cee0;
  border-radius: 3px;
  border-width: 1px;
  &:hover,
  &:focus,
  &:active {
    border-color: #c5cee0;
    border-radius: 3px;
    border-width: 1px;
  }
}

.amplify-input[name="password"] {
  border-color: #c5cee0;
  border-radius: 3px;
  border-width: 1px;
  // border-right-width: 0;
  border-top-right-radius: 0;
}

.amplify-button.amplify-field__show-password {
  border-color: #c5cee0;
  border-radius: 3px;
  border-width: 1px;
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  .amplify-icon {
    color: #c5cee0;
    font-size: 23px;
  }
}

.amplify-button--primary {
  border-radius: 8px;
  background-color: $defaultPrimaryColor;
  &:hover,
  &:focus,
  &:active {
    background-color: $defaultPrimaryColor;
  }
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  box-shadow: none;
}

.login-sign-in-reset-link {
  text-align: left;
  [data-variation="link"] {
    color: #605e5e;
    padding-top: 30px;
    padding-right: 0;
    padding-left: 0;
    height: 40px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    text-decoration-line: none;
    font-size: 14px;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      border-bottom-color: #605e5e;
    }
  }
}

[data-amplify-authenticator-resetpassword],
[data-amplify-authenticator-confirmresetpassword] {
  text-align: left;

  [data-variation="link"] {
    width: fit-content;
    color: #605e5e;
    padding-right: 0;
    padding-left: 0;
    height: 20px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    text-decoration-line: none;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      border-bottom-color: #605e5e;
    }
  }
}

// Sign In & Reset password
.login-step-title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  line-height: 24px;
}

// Sign In
.login-sign-in-title {
  padding: 30px 0 20px 0;
  // padding-top: 15vh;
}

// Reset password
[data-amplify-authenticator-resetpassword]
  [data-variation="link"]:not(.custom-back-to-sign-in-link) {
  display: none;
}

.custom-back-to-sign-in-link {
  padding: 0 !important;
  margin: 0px;
  height: 20px;
}

.login-forgot-password {
  color: #000;
  text-align: left;
  font-size: 14px;
}

.login-forgot-password-title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  padding-bottom: 10px;
  line-height: 24px;
}

// Reset Password Confirm
.login-forgot-password-confirm {
  font-size: 14px;
  padding-top: 0px;
}

//Password Confirm code (hide arrows)
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.amplify-alert--error {
  border-radius: 8px;
  display: none;
}

.amplify-text--error {
  color: #868e96;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.amplify-alert__dismiss span.amplify-icon {
  width: 0rem !important;
}

.login-sign-in-remember-my-details {
  margin-top: 15px;
  font-size: 14px;
}

.login {
  display: flex;
  height: 100%;
  .login-form {
    width: 45vw;
    min-height: 85vh;
    padding: 34px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    &-icon {
      width: 90px;
      padding: 15px 0;
    }
  }

  .login-form-header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .login-form-fields {
    // margin-top: 10px;
  }

  .login-right-content {
    width: 100%;
  }

  .login-image {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    background-size: cover;
    background-position: 50%;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 20vw;
  }

  .login-optional-social-text {
    margin-top: 10px;
    color: #000;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }

  .login-social-buttons {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }

  .login-form-register {
    text-align: center;
    padding: 20px 0px;
  }

  .login-form-register-button {
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 700;
    text-decoration-line: underline;
    text-underline-offset: 4px;
    cursor: pointer;
  }

  .login-status-banner {
    display: flex;
    border-radius: 8px;
    padding: 10px;
    font-size: 14px;
    gap: 10px;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin: 0 0 15px 0;
  }

  .login-error-banner {
    background-color: #ffe5e5;
  }

  .login-error-banner-icon {
    margin-bottom: 5px;
    min-width: 40px;
  }

  .login-success-banner {
    background-color: #cae4c7;
  }

  @media only screen and (max-width: 768px) {
    [data-amplify-container] {
      max-width: 100vw;
    }
    .login-container {
      width: 100%;
    }
    .login {
      flex-direction: column;
      &-sign-in-title {
        padding: 15px 0 15px 0;
      }
    }
    .login-image {
      display: none;
    }
    .login-form {
      width: 90vw;
      align-items: center;
      justify-content: center;
    }
    .login-form-header {
      align-items: center;
    }
    .login-right-content {
      display: none;
    }
  }
}
