.notification-item {
  text-align: start;
  padding: 16px 24px;
  background-color: #ffffff;
  margin-bottom: 12px;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, .125);

  &__unread {
    outline: 2px solid #3da9ff;
    margin: 2px 2px 20px 2px;
  }

  &__link {
    cursor: pointer;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 4px;
  }

  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  &__expand {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-right: auto;
    margin-left: 24px;

    .chevron-icon {
      transition: all 0.1s linear;
    }
  }

  &__expand.expanded {
    .chevron-icon {
      transform: rotate(180deg);
    }
  }

  &__date {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }

  &__body {
    display: -webkit-box;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #605e5e;
    margin-bottom: 4px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 64px;
    max-width: 82ch;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
  }

  &__body.expanded {
    max-height: 100%;
    -webkit-line-clamp: unset;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: end;

    &__container {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    svg {
      margin-left: auto;
      cursor: pointer;
    }

    &-delete-button {
      &:hover {
        color: black;
      }
    }
  }
}
