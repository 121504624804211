/* 
  THEME - STYLING
  NOTE: Changes here will be reflected in all the files that are using these variables.
*/

/********** DEFAULT COLORS ************/
// $defaultPrimaryColor: #1d345e;
// $defaultSecondaryColor: #3eb7aa;
$defaultPrimaryColor: var(--defaultPrimaryColor);
$defaultSecondaryColor: var(--defaultSecondaryColor);
$defaultTextColor: var(--defaultTextColor);

/*************** PAGES ****************/
/* Home */
$homeSectionTitleColor: $defaultPrimaryColor;
$homeSectionTitleMinFontSize: var(--homeSectionTitleMinFontSize);
$homeSectionTitleMinLineHeight: var(--homeSectionTitleMinLineHeight);
/* Footer */
// Prev: #fff
$footerTextColor: var(--footerTextColor);
$footerCopyrightTextColor: var(--footerCopyrightTextColor);
$footerBackgroundColor: var(--footerBackgroundColor);
$footerTrapezoidBackgroundColor: var(--footerTrapezoidBackgroundColor);
$footerContentBackgroundColor: var(--footerContentBackgroundColor);
$footerTopBorderColor: $defaultSecondaryColor;
/* Navigation */
// $navigationBackgroundColor: #fff;
$navigationBackgroundColor: var(--navigationBackgroundColor);
$navigationLinkTextColor: var(--navigationLinkTextColor);
// $navigationLinkTextFontWeight: 600;
$navigationLinkTextFontWeight: var(--navigationLinkTextFontWeight);
$navigationIconColor: var(--navigationIconColor);
$navigationIconActiveColor: $navigationIconColor;
/* User Menu */
$userMenuTextColor: $defaultPrimaryColor;
/* CMS MAIN */
$cmsMainIconsColor: $defaultSecondaryColor;
// $cmsMainHappeningsTitle: #465b85;
$cmsMainHappeningsTitle: var(--cmsMainHappeningsTitle);
$cmsMainHappeningsTitleSub: $defaultPrimaryColor;

/************* COMPONENTS *************/
/* Hover List Menu */
$hoverListMenuItemText: var(--hoverListMenuItemText);
// $hoverListMenuItemTextFontWeight: 600;
$hoverListMenuItemTextFontWeight: var(--hoverListMenuItemTextFontWeight);
$hoverListMenuItemHoverBackgroundColor: var(--hoverListMenuItemHoverBackgroundColor);
// $hoverListMenuItemHoverText: #fff;
$hoverListMenuItemHoverText: var(--hoverListMenuItemHoverText);
$hoverListMenuIconColor: var(--hoverListMenuIconColor) !important;
/* Action Banner */
$actionBannerDefaultBackground: $defaultPrimaryColor;
// $actionBannerDefaultText: #fff;
$actionBannerDefaultText: var(--actionBannerDefaultText);
// $actionBannerAlternativeBackground: #85bf6c;
$actionBannerAlternativeBackground: var(--actionBannerAlternativeBackground);
$actionBannerAlternativeText: $defaultPrimaryColor;
/* Image Carousel */
// $imageCarouselTitleTextColor: #502f2d;
$imageCarouselTitleTextColor: var(--imageCarouselTitleTextColor);
$imageCarouselTitleTextFontSize: var(--imageCarouselTitleTextFontSize);
$imageCarouselTitleTextMinFontSize: var(--imageCarouselTitleTextMinFontSize);
$imageCarouselTitleTextFontWeight: var(--imageCarouselTitleTextFontWeight);
$imageCarouselTitleTextLineHeight: var(--imageCarouselTitleTextLineHeight);
$imageCarouselTitleTextMinLineHeight: var(--imageCarouselTitleTextMinLineHeight);
$imageCarouselSubtitleTextColor: var(--imageCarouselSubtitleTextColor);
$imageCarouselTitleBackgroundColor: var(--imageCarouselTitleBackgroundColor);
/* Tabs */
$tabsTextColor: $defaultPrimaryColor;
$tabsUnderlineColor: $defaultSecondaryColor;
$tabsActiveUnderlineColor: $defaultSecondaryColor;
/* Poi Item */
$poiItemTitleTextColor: $defaultPrimaryColor;
/* Calendar */
$calendarCurrentDayColor: var(--calendarCurrentDayColor);
$calendarCurrentDayBackgroundColor: var(--calendarCurrentDayBackgroundColor);
$calendarSelectedDayBackgroundColor: var(--calendarSelectedDayBackgroundColor);
$calendarTodaysEventBorderColor: var(--calendarTodaysEventBorderColor);
/* Tags */
$tagBackgroundColor: var(--tagBackgroundColor);

/* CMS Settings */
$cmsDetailTitleFontSize: var(--cmsDetailTitleFontSize);
$cmsDetailTitleMinFontSize: var(--cmsDetailTitleFontSize);
$cmsDetailTitleFontWeight: var(--cmsDetailTitleFontWeight);
$cmsDetailTitleLineHeight: var(--cmsDetailTitleLineHeight);
$cmsDetailTitleBottomMargin: var(--cmsDetailTitleBottomMargin);
$cmsDetailTitleTopMargin: var(--cmsDetailTitleTopMargin);

/* FONT SETTINGS */
$fontFamilyPrimary: "primary-font";
$fontFamilyPrimaryBold: "primary-font-bold";
$fontFamilySecondary: "secondary-font";
$fontFamilySecondaryBold: "secondary-font-bold";

// These are going to be exported to be used in non scss files.
:export {
  defaultPrimaryColor: $defaultPrimaryColor;
  defaultSecondaryColor: $defaultSecondaryColor;
  actionBannerAlternativeBackground: $actionBannerAlternativeBackground;
  actionBannerAlternativeText: $actionBannerAlternativeText;
  navigationIconActiveColor: $navigationIconActiveColor;
  navigationIconColor: $navigationIconColor;
  cmsMainIconsColor: $cmsMainIconsColor;
  tagBackgroundColor: $tagBackgroundColor;
  fontFamilyPrimary: $fontFamilyPrimary;
  fontFamilyPrimaryBold: $fontFamilyPrimaryBold;
  fontFamilySecondary: $fontFamilySecondary;
  fontFamilySecondaryBold: $fontFamilySecondaryBold;
}
