@import "@Assets/styles/themedStyles.scss";

#user-profile-my-reservations {
  background: white;
  padding: 10px;
  border-radius: 8px;

  .display-block {
    display: block;
    margin-top: 3px;
    padding: 0 5px;
  }

  .resy-table-cell-pointer {
    cursor: pointer;
  }

  .resy-table-cell-waiver {
    font-weight: bold;
    text-decoration: underline;
    color: black;
    text-underline-offset: 0.25rem;
    cursor: pointer;
  }

}