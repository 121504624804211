.app {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 1125px;

  &-mobile-view-mode {
    margin-bottom: 50px;
  }

  @media only screen and (max-width: 768px) {
    min-width: unset;
  }
}