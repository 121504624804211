.calendar-container {
  padding: 5px 20px 70px 20px;
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  input {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }
  }
  button {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }
  }
}

.calendar-wrapper {
  width: 100%;
}

.calendar-top-content {
  display: flex;
  gap: 30px;
  padding: 15px 0px;
  flex-wrap: wrap;
}

.calendar-category-filters {
  display: flex;
  max-width: 75%;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.calendar-search {
  display: flex;
  flex: 1;
  padding: 5px;
  align-items: center;
  justify-content: flex-end;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.calendar-tags::-webkit-scrollbar {
  display: none;
}

.calendar-tags-scroll-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
}

.calendar-tags-scroll-button-icon {
  color: #323232;
}

.calendar-tags {
  scroll-behavior: smooth;
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: scroll;
  padding: 10px;
  -ms-overflow-style: none; /* Hide scrollbar IE and Edge */
  scrollbar-width: none; /* Hide scrollbar Firefox */
}

.calendar-tags-all {
  width: 45px;
}

.calendar-select-wrapper {
  display: flex;
  gap: 20px;
  min-height: 600px;
  max-height: 600px;
  @media only screen and (max-width: 768px) {
    max-height: 100%;
  }
}

.calendar-select-picker {
  flex: 1;
  padding: 30px;
  background-color: #fff;
  border-radius: 17px;
  max-width: 695px;
  min-width: 450px;
  display: flex;
  align-items: center;
}

.calendar-events-wrapper {
  flex: 1;
}

.calendar-events-row {
  margin-top: 45px;
}

@media only screen and (max-width: 768px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .calendar-container {
    display: block;
    padding: 0 0 50px 0;
  }
  .calendar-top-content {
    padding: 0px 10px;
  }
  .calendar-wrapper {
    min-width: 100%;
  }
  .calendar-category-filters {
    max-width: 90vw;
  }
  .calendar-select-wrapper {
    flex-direction: column;
    background-color: #fff;
    gap: 0;
  }
  .calendar-events-wrapper {
    margin-top: 20px;
    padding: 20px 10px 0 10px;
    background-color: #eee;
  }

  .calendar-events-row {
    flex-direction: column;
    padding: 0 10px;
  }
  .calendar-select-picker {
    padding: 20px 0 0 0;
    min-width: 0;
  }
  .calendar-tags-scroll-button {
    display: none;
  }
  .calendar-top-content {
    padding-bottom: 8px;
    gap: 0px;
    .calendar-search {
      justify-content: flex-start;
    }
  }
}
