@import "@Assets/styles/themedStyles.scss";

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 40px;
  padding: 40px 0 10px 0px;
  .interest-list {
    display: flex;
    gap: 16px;
    padding-bottom: 20px;
  }
}

.card {
  background: #ffffff;
  border-radius: 8px;
  padding: 32px;
  .card-content {
    button .button-content .button-content-text {
      margin-top: 0;
    }
  }

  &-title {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    color: #000000;
    padding-bottom: 10px;
  }
  .card-header {
    display: flex;
    gap: 22px;
    &__editButton {
      height: fit-content;
      background: none;
      color: #465b85;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      text-decoration: underline #465b85;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .permission-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .permission-item {
      max-width: 342px;
      display: flex;
    }
    .permission-name {
      flex-grow: 1;
    }
    .card-actions {
      display: flex;
      gap: 24px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .card-actions.hidden {
      display: none;
      pointer-events: none;
    }
    .button {
      font-family: $fontFamilyPrimary;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #32373f;
      padding: 16px 24px;
      height: 56px;
      &.button-primary {
        color: #e9ecef;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .card-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .interest-list {
    flex-wrap: wrap;
    .button-toggle {
      width: 47%;
    }
  }
  .card {
    &-image {
      width: 80px;
      height: 80px;
    }
    .permission-list {
      .card-actions {
        position: relative;
        justify-content: center;
        padding: 10px 0;
      }
    }
  }
}
