.label-with-value-bolder {
  font-weight: 600;
  color: #000000;
}

.label-with-value-cell {
  flex: 1;
}

.label-with-value-text {
  display: flex;
  flex-direction: column;
  gap: 3px;
  white-space: pre-line;
}

.label-with-value-label {
  color: #5f666d;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
}
