.registration {
  display: flex;
  height: 100%;
  &-form {
    width: 45vw;
    padding: 34px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    &-header {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    &-fields {
      margin-top: 10px;
    }
    &-bottom-text {
      text-align: justify;
      font-size: 14px;
      color: #333333;
    }
    &-marketing {
      margin-top: 20px;
    }
    &-disclaimer {
      margin-top: 20px;
      &-link {
        color: #000;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        text-decoration-line: none;
      }
    }
  }
  &-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  &-subtitle {
    line-height: 19px;
    font-size: 16px;
    margin-top: 5px;
  }
  &-right-content {
    width: 100%;
  }
  &-image {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    background-size: cover;
    background-position: 50%;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 20vw;
  }
  &-create-account {
    margin-top: 20px;
  }
  &-optional-social-text {
    margin-top: 10px;
    color: #000;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  &-social-buttons {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }
  &-error-banner {
    display: flex;
    background-color: #ffe5e5;
    border-radius: 8px;
    padding: 10px;
    font-size: 14px;
    gap: 20px;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin-top: 20px;
    padding-right: 20px;
    &-icon {
      margin-bottom: 5px;
      min-width: 40px;
    }
    &-link {
      border: none;
      background-color: transparent;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 768px) {
  .registration {
    flex-direction: column;
    &-form {
      width: 100%;
    }
    &-right-content {
      display: none;
    }
  }
}
