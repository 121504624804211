@import "@Assets/styles/themedStyles.scss";

.generic-modal {
  padding: 20px;
  min-width: 50vw;
  &__top {
    text-align: right;
  }
  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  &__cta {
    margin: 20px 0;
    height: 80px;
  }
}

@media only screen and (max-width: 768px) {
}
