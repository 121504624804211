.switch-container {
  .switch-label {
    font-size: 14px;
    line-height: 17px;
  }
  .switch {
    --width: 35px;
    --height: 21px;
    --border-radius: calc(var(--height) / 2);
    margin-top: 15px;
    display: inline-block;
    cursor: pointer;
    border-width: 2px;

    .switch-input {
      display: none;
    }

    .switch-fill {
      position: relative;
      width: var(--width);
      height: var(--height);
      border-radius: var(--border-radius);
      background: #fff;
      transition: background 0.2s;
      border-style: solid;
      border-color: #495057;
      border-width: 2px;
    }

    .switch-fill::after {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      height: 19px;
      width: 19px;
      background: #212529;
      border-radius: var(--border-radius);
      transition: transform 0.2s;
      border-width: 2px;
    }

    .switch-input:checked ~ .switch-fill {
      background: #3da9ff;
    }

    .switch-input:checked ~ .switch-fill::after {
      transform: translateX(15px);
    }
  }
}

.switch-container-inline {
  display: flex;
  align-items: center;
  gap: 10px;
  .switch {
    margin-top: 0px;
  }
}
