.password-reset {
  display: flex;
  height: 100%;
  width: 100%;
  .card-actions {
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.password-reset-form {
  width: 502px;
  padding: 34px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.password-reset-form-header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &-title {
      font-size: 21px;
      font-weight: 600;
    }
  }
}

.password-reset-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.password-reset-subtitle {
  line-height: 19px;
  font-size: 16px;
  margin-top: 5px;
}

.password-reset-form-fields {
  margin-top: 10px;
}

.password-reset-right-content {
  width: 100%;
}

.password-reset-image {
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  background-size: cover;
  background-position: 50%;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 20vw;
}

.password-reset-create-account {
  margin-top: 20px;
}

.password-reset-optional-social-text {
  margin-top: 10px;
  color: #000;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.password-reset-social-buttons {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.password-reset-error-banner {
  display: flex;
  background-color: #ffe5e5;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  gap: 20px;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-top: 20px;
  padding-right: 20px;
}

.password-reset-error-banner-icon {
  margin-bottom: 5px;
  min-width: 40px;
}

.password-reset-error-banner-link {
  border: none;
  background-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .password-reset {
    flex-direction: column;
  }

  .password-reset-right-content {
    display: none;
  }

  .password-reset-form {
    width: 100%;
    padding: 20px;
    &-fields {
      margin-top: 0px;
    }
  }
}
