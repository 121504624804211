.basic-3-column-footer-template {
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 25px;
  align-items: start;
  grid-template-areas:
    "tenant-footer-links tenant-logo tenant-mobile-app-links"
    "tenant-footer-links tenant-logo tenant-copyright"
    "tenant-footer-links version-number tenant-admin-email";

  .tenant-logo {
    grid-area: tenant-logo;
    justify-content: center;
  }

  .tenant-copyright {
    grid-area: tenant-copyright;
  }

  .version-number {
    grid-area: version-number;
  }

  .tenant-footer-links {
    grid-area: tenant-footer-links;
  }

  .tenant-admin-email {
    grid-area: tenant-admin-email;
  }

  .tenant-mobile-app-links {
    grid-area: tenant-mobile-app-links;
  }

  @media (max-width: 768px) {
    padding: 20px;
    grid-template-columns: 1fr;
    grid-row-gap: 25px;
    grid-template-areas:
      "tenant-logo"
      "tenant-footer-links"
      "tenant-mobile-app-links"
      "tenant-admin-email"
      "tenant-copyright"
      "version-number";
  }
}
