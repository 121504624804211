@import "@Assets/styles/themedStyles.scss";
.poi-item {
  padding: 20px 20px 20px 20px;
  background-color: #fff;
  font-size: 16px;
  line-height: 19px;
  color: #605e5e;
  border-radius: 8px;
  border: solid 2px #fff;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &-selected {
    border-color: #3da9ff;
    transition-delay: 0.1s;
  }
  &-type {
    font-weight: 600;
  }
  &-title {
    font-size: 20px;
    font-weight: 600;
    color: $poiItemTitleTextColor;
    margin-top: 5px;
  }
  &-location {
    margin-top: 5px;
  }
}
