@import "@Assets/styles/themedStyles.scss";

.cms-detail {
  &__breadcrumb {
    margin: 0 50px;
    padding: 30px 0px;
  }
  &__back {
    display: none;
    width: 80px;
    margin: 0px 0px 10px 0;
  }
  &__content {
    display: flex;
    gap: 20px;
    margin: 0 50px 80px 50px;
    background-color: #fff;
    align-items: center;
    border-radius: 4px;
    &__left {
      flex: 1;
      padding: 35px 50px;
      color: $defaultPrimaryColor;
      font-size: 16px;
      line-height: 19px;
      align-self: flex-start;
      &__title {
        color: $defaultPrimaryColor;
        font-size: $cmsDetailTitleFontSize;
        line-height: $cmsDetailTitleLineHeight;
        font-weight: $cmsDetailTitleFontWeight;
        font-family: $fontFamilySecondary, $fontFamilyPrimary;
        margin-bottom: $cmsDetailTitleBottomMargin;
        margin-top: $cmsDetailTitleTopMargin;
      }
    }
    &__right {
      height: 630px;
      max-width: 55%;
      &__image {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        height: 100%;
        max-width: 100%;
        object-fit: cover;
      }
      &__image-top {
        align-self: flex-start;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .cms-detail {
    padding: 10px 20px 20px 20px;
    &__breadcrumb {
      display: none;
    }
    &__back {
      display: block;
    }
    &__content {
      flex-direction: column;
      padding: 0 0 20px 0;
      margin: 0 0 20px 0;
      &__left {
        width: 100%;
        padding: 20px;
        word-wrap: break-word;
        &__title {
          font-size: $cmsDetailTitleMinFontSize;
          padding: 0 20px;
        }
      }
      &__right {
        height: auto;
        &__image {
          width: 70vw;
          height: auto;
          object-fit: cover;
        }
        &__image-top {
          align-self: center;
        }
      }
    }
  }
}
