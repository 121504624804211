.profile {
  width: 100%;
  padding: 40px 72px;
  &-title {
    font-size: 48px;
    line-height: 57px;
  }
  &-tabs {
    margin: 10px 0 20px 0px;
    box-shadow: inset 0px -2px 0px #c4c4c4;
  }
  &-tab-content {
    height: auto;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .profile {
    width: 100%;
    padding: 20px 20px;
    &-title {
      font-size: 25px;
      line-height: 20px;
    }
  }
}
