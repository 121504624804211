// TODO: Refactor to follow a naming convention of the styles to be more friendly to read.
.payments {
  width: 100%;
  &-breadcrumb {
    margin-top: 30px;
  }
  &-navigation-min {
    display: none;
    width: 80px;
    margin: 10px 0px 0px 0;
  }
  &-content {
    display: flex;
    gap: 20px;
    flex-grow: 1;
  }
  &-text {
    font-size: 14px;
    line-height: 17px;
    color: #32373f;
  }
  &-detail-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    .payments-name {
      font-size: 32px;
      line-height: 38px;
      font-weight: 600;
      padding: 0 30px 0px 30px;
    }

    .payments-datetime {
      padding: 0px 30px;
    }
  }
  &-detail-wrapper-left {
    padding: 30px 0px;
    min-width: 25vw;
  }
  &-card {
    flex-grow: 1;
    .payments-products {
      &-card {
        position: relative;
        display: flex;
        flex-direction: column;
        font-style: normal;
        margin-bottom: 24px;
        height: 100%;
        &__banner {
          display: flex;
          gap: 10px;
          background-color: #e9ecef;
          padding: 15px;
          border-radius: 8px;
          &__text {
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
          }
        }
        &__confirmation {
          flex-grow: 1;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &__row_payment {
          display: flex;
          flex-direction: row;
        }
        &__total {
          margin-top: 24px;
          margin-left: auto;
          width: 337px;
          height: 72px;
          background: #f1f3f5;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          .ticket-total {
            display: inline-flex;
            justify-content: space-between;
            width: 100%;
            padding: 24px;
          }
        }
        &__header {
          display: flex;
          box-shadow: inset 0px -2px 0px #c4c4c4;
        }
        &__title {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #32373f;
          margin: 0;
          padding: 15px;
          flex-shrink: 0;
          &__addon {
            font-size: 16px;
            line-height: 19px;
          }
        }
        &__list {
          display: flex;
          flex-direction: column;
        }
        &__inputs {
          display: flex;
          flex-wrap: nowrap;
          gap: 18px;
          align-items: flex-end;
          margin: 18px 0;
          .button-content .button-content-text {
            margin-top: 0;
          }
          &__discount {
            flex-grow: 1;
          }
          &__button {
            width: 150px;
          }
        }
        &__action {
          display: flex;
          justify-content: flex-end;
          .box.box-stripe {
            margin-right: auto;
            margin-left: 16px;
          }
          .box {
            max-width: 441px;
            .inputs {
              padding: 16px;
            }
            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
            }
          }
          &__button {
            width: 342px;
            align-self: flex-end;
          }
        }
        &__payment {
          .row {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
            box-shadow: inset 0px 2px 0px #dae4f8;
            &.header {
              width: 100%;
              box-shadow: inset 0px -2px 0px #c4c4c4;
            }
            display: flex;
            justify-content: space-between;

            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #333333;
            .option {
              padding: 16px 16px 8px 24px;
              width: 300px;
              display: flex;
              justify-content: space-between;
              input {
                margin-right: 16px;
              }
            }
          }
          .no-line {
            box-shadow: none;
          }
          .title {
            padding: 16px;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #32373f;
          }
          .logos {
            display: inline-flex;
            width: fit-content;
            gap: 16px;
          }
        }
        &__row,
        &__row_payment {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          overflow: hidden;
          align-items: center;
          flex-wrap: nowrap;
          padding: 10px 16px;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
          box-shadow: inset 0px -2px 0px #c4c4c4;
          > div {
            display: flex;
            flex: 1;
            justify-content: flex-start;
          }
          > div:first-child {
            justify-content: flex-start;
          }
          > div:last-child {
            justify-content: flex-end;
          }
          .payments-products__type {
          }
          .payments-products__type__bold {
            font-weight: 600;
          }

          .payments-products__price {
          }
          .payments-products__selector {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            flex-shrink: 0;
            white-space: nowrap;
            width: 89px;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
              background: none;
              color: inherit;
              border: none;
              padding: 0;
              font: inherit;
              cursor: pointer;
              outline: inherit;
              text-align: center;
              vertical-align: middle;
              height: 19px;
              svg {
                vertical-align: middle;
                margin: auto;
              }
            }
            span {
              margin: 0 20px;
            }
          }
        }
      }
      &__label_payment {
        padding: 2px 5px;
        width: calc(25% - 10px); /* 25% width minus 10px for margin */
        min-width: calc(25% - 10px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
      &__label_payment:first-of-type {
        text-align: start;
      }
      &__label_payment:last-of-type {
        text-align: end;
      }
    }
  }

  .payments-products {
    &__bottom-content {
      display: flex;
      flex-direction: column;
      gap: 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #32373f;
      padding: 30px 30px 0 30px;
      .content-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
      }
      .mb-16 {
        margin-bottom: 16px;
      }
      .date-label {
        font-weight: 600;
      }
      .time-label {
        font-weight: 600;
      }
    }
  }
  .payments-products__edit-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    text-underline-offset: 4px;
    color: #000000;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .payments-products-card {
    &__confirmation {
      padding: 10px;
      text-align: center;
    }
  }
  &-carousel {
    margin-top: 20px;
  }
  &-bold {
    font-weight: 700;
  }
  &-addons {
    padding-top: 40px;
    .payments-products-card__row:last-child {
      box-shadow: none;
    }
    // div.payments-addons > div:nth-child(3)
  }
}

@media only screen and (max-width: 768px) {
  .payments {
    padding: 0 15px;
    &-card {
      margin-bottom: 50px;
      .payments-products {
        &__label_payment {
          width: 100%;
        }
        &-card {
          &__action {
            .box.box-stripe {
              margin: 0;
              .linkify-text {
                text-align: center;
              }
            }
          }
        }
      }
    }
    &-content {
      flex-direction: column;
      gap: 10px;
    }
    &-detail-wrapper {
      padding: 30px 20px;
      margin-top: 10px;
    }
    &-detail-wrapper-left {
      padding: 30px 0px;
    }
    &-breadcrumb {
      display: none;
    }
    &-navigation-min {
      display: block;
    }
    &-products-card {
      &__header {
        text-align: center;
        padding: 15px 15px 20px 15px;
        .payments-products-card__title {
          padding: 0;
          width: 100%;
        }
      }
      .payments-products-card__inputs {
        flex-direction: column;
        align-items: normal;
        &__button {
          width: 100%;
        }
      }
      .payments-products-card__row {
        .payments-products__type {
          max-width: 30%;
          white-space: normal;
        }
      }
      .payments-products-card__row.disabled {
        color: #c4c4c4 !important;
      }

      .payments-products-card__row_payment {
        flex-direction: column;
        padding: 10px;
        position: relative;
        align-items: flex-start;
        .payments-products__edit-link__payment {
          position: absolute;
          right: 10px;
          bottom: 13px;
          text-underline-offset: 4px;
        }
        .payments-products__label_payment {
          padding: 2px 5px;
        }
      }
      .payments-products-card__total {
        width: 100%;
      }
      .payments-products-card__payment {
        padding: 40px 0 0 0;
      }
      .payments-products-card {
        &__action {
          &__button {
            width: 100%;
          }
        }
      }
    }
    .payments-detail-wrapper {
      .payments-carousel {
        margin: 0;
      }
    }
  }
}
