.instagram-feed-row {
  display: flex;
  gap: 10px;
  margin: 10px 0px;
  img {
    flex: 1;
    overflow: hidden;
    position: relative;
  }
}

@media only screen and (max-width: 768px) {
  .instagram-feed-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    img {
      min-width: 40vw;
      flex: 1;
      overflow: hidden;
      position: relative;
    }
  }
}
