@import "@Assets/styles/themedStyles.scss";

.event-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-list-header-no-events {
  font-size: 20px;
  margin-top: 20px;
  text-transform: capitalize;
}

.event-list-show-all-events {
  border: none;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  text-decoration-line: underline;
  text-underline-offset: 4px;
  text-transform: capitalize;
  cursor: pointer;
}

.events-list-title {
  font-family: $fontFamilySecondary, $fontFamilyPrimary;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border-bottom: solid 2px $defaultSecondaryColor;
  padding-bottom: .75rem;
  margin-bottom: 1rem;
}

.events-list-title-icon {
  font-size: 27px;
}

.events-list-title-text {
  font-size: 48px;
  line-height: 39px;
  color: $defaultPrimaryColor;
}

.event-list {
  width: 100%;
  overflow-y: scroll;
}

.event-list-items {
  display: flex;
  gap: 10px;
}

.event-list-items-scroll-control {
  display: flex;
  align-items: center;
  position: relative;
}

.event-list-items-scroll-control-button {
  border: none;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  cursor: pointer;
  flex: 1;
  max-width: 50px;
  align-items: center;
  position: absolute;
  z-index: 200;
  right: 0;
  top: calc(50% - 18px);
  min-width: 30px;
}

.event-list-items-scroll-control-button-left {
  margin-right: 3px;
  left: 0;
}

.event-list-items-scroll-control-button-hidden {
  visibility: hidden;
}

.events-list-items-wrapper {
  gap: 20px;
  flex: 1;
  overflow-x: scroll;
  width: 100%;
  scroll-behavior: smooth;
}

.events-list-item-last {
  margin-right: 10px;
}

.events-list-items-wrapper-inline {
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Event list scrollbar */
.event-list::-webkit-scrollbar {
  display: block;
  width: 8px;
}

.event-list::-webkit-scrollbar-track {
  // background: #f1f1f1;
  background: none;
}

.event-list::-webkit-scrollbar-thumb {
  background: #888;
  //background: #e2e8f0;
  border-radius: 10px;
}

.event-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-corner {
  background: none;
}

@media only screen and (max-width: 768px) {
  .event-list-items-scroll-control-button {
    display: none;
  }
  .events-list-title {
    padding-left: 5px;
    &-text {
      font-size: 36px;
    }
  }
  .event-list-items {
    gap: 0px;
  }
  .events-list-items-wrapper {
    gap: 10px;
  }
  .events-list-items-wrapper-inline {
    margin-right: 0;
    margin-left: 0;
  }
  .event-list-item-container {
    display: flex;
    flex-direction: column;
  }
  .event-list::-webkit-scrollbar {
    display: none;
  }
  .event-list-header {
    flex-direction: column;
  }
  .event-list-header-no-events {
    text-align: justify;
    font-size: 15px;
    margin-top: 0px;
  }
  .event-list-show-all-events {
    background-color: #fff;
    margin-bottom: 20px;
  }
}
