@import "@Assets/styles/themedStyles.scss";

.hover-list-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  font-size: 14px;
  line-height: 17px;
  cursor: default;
  background-color: $navigationBackgroundColor;

  &-item {
    &-button {
      display: flex;
      gap: 10px;
      color: $hoverListMenuItemText;
      font-weight: $hoverListMenuItemTextFontWeight;
      &-text {
        text-decoration: none;
        color: $hoverListMenuItemText;
      }
    }

    &-default {
      display: none;
      position: absolute;
      top: 50px;
      background-color: $navigationBackgroundColor;
      width: 100%;
      text-align: left;
      flex-direction: column;
      left: -20px;
      min-width: 200px;
      z-index: 200;
    }

    &-text {
      padding: 10px 20px;
      color: $hoverListMenuItemText;
      text-decoration: none;

      &:hover {
        font-weight: 600;
        background-color: $hoverListMenuItemHoverBackgroundColor;
        color: $hoverListMenuItemHoverText;
      }
    }
  }

  &-icons {
    display: flex;

    & i {
      color: $hoverListMenuIconColor;
    }

    &-min {
      display: none;

      & i {
        color: $hoverListMenuIconColor;
      }
    }
  }

  &-icon-expanded {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .hover-list-menu {
    height: 100%;

    &-item {
      &-default {
        position: relative;
        top: 0;
        left: 0;
      }

      &-button {
        justify-content: space-between;

        &-text {
          font-weight: 700;
        }
      }

      &-text {
        padding: 5px 15px;
        width: 100%;

        &:hover {
          font-weight: 400;
        }
      }

      &-visible {
        display: flex;
      }

      &-hidden {
        display: none;
      }
    }

    &-icons {
      display: none;

      &-min {
        display: flex;

      }
    }

    &-icon-expanded {
      display: block;
    }
  }
}

