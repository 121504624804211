@import "@Assets/styles/themedStyles.scss";
.tabs {
  display: flex;
  overflow-x: scroll;
  &-underlined {
    border-bottom: solid $tabsUnderlineColor 2px;
  }
  &-tab {
    padding: 15px 20px 7px 20px;
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-color: transparent;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: $tabsTextColor;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: flex-end;
    white-space: nowrap;
    &-icon {
      width: 20px;
      height: 20px;
    }
    &-all {
      min-width: 100px;
      justify-content: center;
    }
    &-active {
      border-color: $tabsActiveUnderlineColor;
      font-weight: 600;
      color: $tabsTextColor;
      transition: left 0.25s;
    }
  }
}
