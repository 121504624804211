.button-toggle {
  width: 163px;
  height: 96px;
  padding: 16px 20px;
  border-style: solid;
  border-radius: 8px;
  border-width: 1px;
  border-color: #5f666d;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  &__selected {
    font-size: 20px;
    line-height: 24px;
    border: 3px solid #1890ff;
  }
}
