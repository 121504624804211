@import "@Assets/styles/themedStyles.scss";

.user-menu {
  padding: 10px 5px;
  font-size: 14px;
  line-height: 17px;
  cursor: default;
  text-align: left;
  width: 100%;
}

.user-menu-user-text {
  font-weight: 600;
  padding: 0px 20px;
  color: $userMenuTextColor;
}

.user-menu-loyalty {
  display: flex;
  gap: 20px;
  margin: 5px 0px;
  border-top: solid #dae4f8 2px;
  align-items: center;
}

.user-menu-image {
  width: 33px;
  height: 34px;
}

.user-menu-image-wrapper {
  max-width: 40px;
  text-align: right;
}

.user-menu-loyalty-points {
  font-weight: 600;
  margin-top: 5px;
}

.user-menu-text {
  padding: 5px 20px;
  color: $defaultPrimaryColor;
  & a {
    color: $defaultPrimaryColor;
    text-decoration: none;
    text-decoration-line: none;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
      text-decoration-line: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .user-menu {
    padding: 20px 0px 0px 0px;
  }
  .user-menu-user-text {
    padding: 0px 25px;
    font-weight: 700;
  }
  .user-menu-loyalty {
    margin-top: 20px;
    padding-left: 25px;
  }
  .user-menu-text {
    display: none;
  }
}
