@import "@Assets/styles/themedStyles.scss";

.action-banner {
  z-index: 101;
  padding: 10px;
  background-color: $actionBannerDefaultBackground;
  display: flex;
  align-items: center;
  justify-content: center;
  &-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.inherit-color {
  color: inherit !important;
}

.action-banner-text {
  font-size: 14px;
  color: $actionBannerDefaultText;
  margin-top: 2px;
  margin-left: 5px;

  p {
    margin: 0;
  }
}

.action-banner-text-underline {
  text-decoration-line: underline;
  text-underline-offset: 4px;
}

.action-banner-text-link {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .action-banner {
    flex-direction: column;
    border-radius: 10px;
    margin: 5px 5px;
  }
}
