@import "@Assets/styles/themedStyles.scss";

.cms-main {
  text-align: center;
  &__list{
    padding: 0 0px 0px 30px;
    margin: 0 0 10px 0;
    &__link {
      color: #000;
      text-decoration-line: none;
    }
  }
  &__section {
    background-color: none;
    margin-top: 70px;
    font-size: 14px;
    line-height: 20px;
    &__header {
      margin-top: 70px;
      padding: 0 70px;
      display: flex;
      justify-content: center;
      gap: 15px;
      font-size: 72px;
      line-height: 86px;
      &__left {
        font-weight: 600;
        color: #465b85;
      }
      &__right {
        color: #1d345e;
      }
    }
    &__content {
      background-color: #fff;
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 7%;
      &__border {
        // Don't forget to add right padding and the border to your injected html
        gap: 2.5rem;
      }
      &__left {
        flex: 1;
        &__html {
          padding: 80px 0 80px 80px;
          height: 100%;
          &__border {
            // Assumes injected html has padding top/bottom 20px
            padding: 60px 0 60px 80px;
          }
          &__no-right {
            padding: 80px;
          }
        }
        &__image-right {
          max-width: 42%;
        }
        &__image-left {
          height: 70vh;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
        &__contain-image {
          object-fit: contain !important;
        }
      }
      &__right {
        flex: 1;
        &__html {
          padding: 80px 80px 80px 0;
          &__border {
            padding: 60px 80px 60px 0;
          }
        }
        &__image-left {
          max-width: 42%;
        }
        &__image-right {
          height: 70vh;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
        &__contain-image {
          object-fit: contain !important;
        }
      }
    }
  }
  &__clickable {
    cursor: pointer;
  }
  &__icon {
    width: 20px;
    height: 20px;
  }
  &__upcoming-events {
    padding: 0 70px 0px 70px;
    &__title {
      display: flex;
      align-items: center;
      gap: 20px;
      font-size: 48px;
      margin: 30px 20px 20px 20px;
      color: $defaultPrimaryColor;
      border-bottom: solid $defaultSecondaryColor 2px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .cms-main {
    &__upcoming-events {
      padding: 20px;
      &__title {
        font-size: 28px;
        margin: 30px 0px 20px 5px;
      }
    }
    &__section {
      margin-top: 50px;
      &__header {
        flex-direction: column;
        font-size: calc(1rem + 5vw);
        line-height: calc(2.5rem + 2vh);
        padding: 0 0 0 2rem;
        gap: .5rem
      }
      &__content {
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px 0;
        gap: 0;
        &__border {
          gap: 1.25rem;
          padding: 2rem;
        }
        &__left {
          &__html {
            padding: 0 2rem 1rem 2rem;
            &__border {
              padding: 0;
              border-right: none;
              border-bottom: 1px solid $defaultSecondaryColor;
            }
          }
          &__image-right {
            max-width: 100%;
          }
          &__image-left {
            height: 55vh;
          }
          img {
            max-width: 100vw;
            height: 55vh;
          }
        }
        &__right {
          &__html {
            padding: 0 2rem 1rem 2rem;
            &__border {
              padding: 0;
            }
          }
          &__image-left {
            max-width: 100%;
          }
          &__image-right {
            height: 55vh;
          }
          img {
            max-width: 100vw;
            height: 55vh;
          }
        }
      }
    }
  }
}
